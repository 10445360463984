<div class="settings">
  <label *ngIf="relaySetting" class="settings__dropdown-name">
    {{ relaySetting.key | translate }}
  </label>
  <div>
    <mat-form-field class="settings__dropdown" *ngIf="relaySetting">
      <mat-select
        class="settings__relay"
        [(ngModel)]="relaySetting.translatedSelectedValue"
        panelClass="relay-setting-panel-class"
        disableOptionCentering
      >
        <mat-option
          *ngFor="let val of relaySetting.translatedvaluesList"
          [value]="val.translatedValue"
          (click)="onSelect(val)"
        >
          {{ val.translatedValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<!-- <div *ngIf="characteristicList" class="key">Active characteristic</div> -->
<mat-form-field class="settings__dropdown" *ngIf="characteristicList">
  <mat-select
    class="settings__relay"
    [(ngModel)]="activeCharacteristic"
    [ngClass]="isDisable ? 'settings__disable' : ''"
    [disabled]="isDisable"
    disableOptionCentering
    panelClass="setting-panel-class"
  >
    <mat-option
      *ngFor="let val of characteristicList"
      [value]="val"
      (click)="onCharacteristicChange(val)"
    >
      {{ val | translate }}
    </mat-option>
  </mat-select>
</mat-form-field>
