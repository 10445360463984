<div
  class="d-flex h-100 flex-column justify-content-around overflow-hidden p-4"
>
  <div class="d-flex justify-content-between">
    <h4>
      {{ data.header | translate }}
    </h4>
    <button
      ui-button
      size="icon-small"
      variant="tertiary"
      (click)="close()"
      y
      *ngIf="data.header !== 'session-timeout-header'"
    >
      <app-close-icon />
    </button>
  </div>
  <div class="overflow-hidden text-break">
    <p>
      {{ data.content | translate }}
    </p>
  </div>
  <div class="d-flex gap-3 justify-content-end">
    <button
      ui-button
      *ngFor="let action of data.actions"
      variant="{{ action.type }}"
      [mat-dialog-close]="data"
      (click)="onActionClick(action.type)"
    >
      {{ action.action | translate }}
    </button>
  </div>
</div>
