import HighChartHelper from "../../../high-chart/helpers/high-chart.helper";
import ProjectService from "../../drive/projectService";
import FileService from "../../file/FileService";
import AuxillaryLineService from "../AuxillaryLineService";
import FacadeService from "../FacadeService";
import LineConfigurationService from "../LineConfigurationService";
import ProductCatalogService from "../ProductCatalogService";
import ProductService from "../ProductService";
import SessionService from "../SessionService";
import MarkService from "../MarkService";
import AuthService from "../AuthService";
import DocumentService from "../DocumentService";
import { LocalizationService } from "../../localization/localization.service";
import MarksHelper from "../../../high-chart/helpers/marks.helper";
import SCLHelper from "../../../high-chart/helpers/short-circuit-line.helper";
export default class ServiceFactory {
  public get ProductService(): ProductService {
    return this.productService;
  }
  public get ProductCatalogService(): ProductCatalogService {
    return this.productCatalogService;
  }
  public get ProjectService(): ProjectService {
    return this.driveProjectService;
  }
  public get LineConfigurationService(): LineConfigurationService {
    return this.lineConfigurationService;
  }

  public get FileImportService(): FileService {
    return this.fileImportService;
  }
  public get FacadeService(): FacadeService {
    return this.facadeService;
  }
  public get SessionService(): SessionService {
    return this.sessionService;
  }
  public get AuxillaryLineService(): AuxillaryLineService {
    return this.auxillaryLineService;
  }
  public get AuthService(): AuthService {
    return this.authService;
  }
  public get HighChartHelper(): HighChartHelper {
    return this.highChartHelper;
  }
  public get MarksHelper(): MarksHelper {
    return this.marksHelper;
  }
  public get SCLHelper(): SCLHelper {
    return this.sclHelper;
  }
  public get MarkService(): MarkService {
    return this.marksService;
  }
  public get DocumentService(): DocumentService {
    return this.documentService;
  }
  public get LocalizationService(): LocalizationService {
    return this.localizationService;
  }
  private productService: ProductService;
  private productCatalogService: ProductCatalogService;
  private driveProjectService: ProjectService;
  private lineConfigurationService: LineConfigurationService;
  private fileImportService: FileService;
  private facadeService: FacadeService;
  private sessionService: SessionService;
  private auxillaryLineService: AuxillaryLineService;
  private highChartHelper: HighChartHelper;
  private marksHelper: MarksHelper;
  private sclHelper: SCLHelper;
  private marksService: MarkService;
  private authService: AuthService;
  private documentService: DocumentService;
  private localizationService: LocalizationService;
  constructor() {
    this.productService = new ProductService();
    this.productCatalogService = new ProductCatalogService();
    this.driveProjectService = new ProjectService();
    this.lineConfigurationService = new LineConfigurationService();
    this.fileImportService = new FileService();
    this.facadeService = new FacadeService();
    this.sessionService = new SessionService();
    this.auxillaryLineService = new AuxillaryLineService();
    this.highChartHelper = new HighChartHelper();
    this.marksHelper = new MarksHelper();
    this.sclHelper = new SCLHelper();
    this.marksService = new MarkService();
    this.authService = new AuthService();
    this.documentService = new DocumentService();
    this.localizationService = new LocalizationService();
  }
}
// ** All related service factory  */
export const serviceFactory = new ServiceFactory();
