import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import { CustomLoader } from "../language/custom.loader";
import { LanguageMissingTranslationHandler } from "../language/language.missing-translation-handler";
import { ProductAttributesComponent } from "./product-attributes/product-attributes.component";
import { ProductCatalogTreeComponent } from "./product-catalog-tree/product-catalog-tree.component";
import { DeviceCatalogDialogComponent } from "./device-catalog-dialog/device-catalog-dialog.component";
import { CommonHelperModule } from "../common/common-helper.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: LanguageMissingTranslationHandler,
      },
    }),
    CommonHelperModule,
    ProductAttributesComponent,
    ProductCatalogTreeComponent,
    DeviceCatalogDialogComponent,
  ],
  exports: [
    ProductAttributesComponent,
    ProductCatalogTreeComponent,
    DeviceCatalogDialogComponent,
  ],
  providers: [DeviceCatalogDialogComponent],
  bootstrap: [],
})
export class DeviceCatalogModule {}
