<svg
  width="20"
  height="20"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.9998 5.82813V15.9987H10.9998V5.82825L7.70728 9.12109L6.29321 7.70703L11.9998 2L17.7068 7.70703L16.2927 9.12109L12.9998 5.82813ZM4 22V20H20V22H4Z"
    fill="currentColor"
  />
  "
</svg>
