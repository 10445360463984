import Product from "./product";

export default class BaseModel {
  name?: string;
  isChecked?: boolean = false;
  toggleSlashEye?: boolean = false;
  index!: string;
  type: string;
  productAttributes: Product;
  nominalVoltage: number;
  productGroup: string;
  orderNumber: string;
  voltage: string;
  ambientTemparature: number;
  isLineConfigurationOpen?: boolean = false;
}
