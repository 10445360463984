<h3 class="px-4 py-4">{{ headerLabel() | translate }}</h3>
<form
  class="px-4 pb-4 flex flex-col flex-grow overflow-y-auto justify-between"
  [formGroup]="projectForm"
  (submit)="onSubmit()"
>
  <div>
    <!-- country -->
    <label for="country">
      {{ "Country" | translate }}
    </label>
    <mat-form-field appearance="outline" class="w-100">
      <mat-select formControlName="country" id="country">
        @for (country of this.countries; track $index) {
          <mat-option [value]="country.code">{{ country.label }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <!-- name -->
    <div class="d-flex flex-column-reverse">
      <input
        ui-input
        type="text"
        maxlength="100"
        id="project-name"
        formControlName="projectName"
      />
      <label for="project-name">
        *{{ "project" | translate
        }}<span>
          {{ "(" }}{{ projectForm.get("projectName").value?.length ?? 0 }}/100{{
            ")"
          }}
        </span>
      </label>
    </div>

    <!-- description -->
    <div class="d-flex flex-column-reverse mt-2">
      <input
        ui-input
        type="text"
        maxlength="500"
        id="project-description"
        formControlName="projectDescription"
      />
      <label for="project-description"
        >{{ "projectdesc" | translate }}
        <span>
          {{ " ("
          }}{{ projectForm.get("projectDescription").value?.length ?? 0 }}/500{{
            ")"
          }}
        </span></label
      >
    </div>

    <!-- customer/client -->
    <div class="d-flex flex-column-reverse mt-2">
      <input
        ui-input
        id="client"
        type="text"
        maxlength="500"
        formControlName="customer"
      />
      <label for="client">
        {{ "customer" | translate }}
        <span>
          {{ " (" }}{{ projectForm.get("customer").value?.length ?? 0 }}/500{{
            ")"
          }}
        </span>
      </label>
    </div>

    <!-- location -->
    <div class="d-flex flex-column-reverse mt-2">
      <input
        ui-input
        id="location"
        type="text"
        maxlength="500"
        formControlName="location"
      />
      <label for="location">
        {{ "location" | translate }}
        <span>
          {{ " (" }}{{ projectForm.get("location").value?.length ?? 0 }}/500{{
            ")"
          }}
        </span>
      </label>
    </div>

    <!-- office -->
    <div class="d-flex flex-column-reverse mt-2">
      <input
        type="text"
        ui-input
        id="office"
        formControlName="office"
        maxlength="500"
      />
      <label for="office"
        >{{ "ProjectData_UserOffice" | translate }}
        <span>
          {{ " (" }}{{ projectForm.get("office").value?.length ?? 0 }}/500{{
            ")"
          }}
        </span></label
      >
    </div>

    <!-- comment -->
    <div class="d-flex flex-column-reverse mt-2">
      <textarea
        ui-input
        rows="8"
        id="comment"
        style="resize: none"
        formControlName="comment"
        maxlength="500"
      ></textarea>
      <label for="comment">
        {{ "comment" | translate }}
        <span>
          {{ " (" }}{{ projectForm.get("comment").value?.length ?? 0 }}/500{{
            ")"
          }}
        </span>
      </label>
    </div>
  </div>

  <div class="flex gap-2 justify-content-end mt-3">
    <button
      ui-button
      variant="secondary"
      type="button"
      (click)="onCancelProject()"
    >
      {{ "CANCEL" | translate }}
    </button>
    <button
      type="submit"
      ui-button
      [disabled]="projectForm.invalid || isLoading()"
    >
      @if (isLoading()) {
        <!-- TODO: Get official loader icon -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="animate-spin"
        >
          <path d="M21 12a9 9 0 1 1-6.219-8.56" />
        </svg>
      } @else {
        {{ "ConfirmButton_TEXT" | translate }}
      }
    </button>
  </div>
</form>
