import {
  Component,
  computed,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatOption } from "@angular/material/core";
import { MatSelect } from "@angular/material/select";
import { MatFormField } from "@angular/material/form-field";
import { TranslateModule } from "@ngx-translate/core";
import { NgEventBus } from "ng-event-bus";
import { InputComponent } from "../../../../../packages/ui/src/primitives/input/input.component";
import { Constants } from "../../../models/constants/Constants";
import ProjectInfo from "../../../models/drive/projectInfo";
import { serviceFactory } from "../../../services/serviceLayer/servicefactory/serviceFactory";
import { SessionExpiredService } from "../../../services/serviceLayer/session-expired-dialog.service";
import { ToasterService } from "../../../services/toaster.service";
import { ButtonComponent } from "../../../../../packages/ui/src/primitives/button/button.component";
import { TCountry, TProject } from "../project-list.service";

interface ProjectForm {
  projectName: FormControl<string>;
  projectDescription?: FormControl<string>;
  customer?: FormControl<string>;
  country?: FormControl<string>;
  office?: FormControl<string>;
  location?: FormControl<string>;
  comment?: FormControl<string>;
}

@Component({
  selector: "app-project-sidebar",
  templateUrl: "./project-sidebar.component.html",
  styleUrls: ["./project-sidebar.component.css"],
  standalone: true,
  imports: [
    MatFormField,
    InputComponent,
    ButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    MatOption,
    TranslateModule,
    FormsModule,
    MatSelect,
  ],
})
export class ProjectSidebarComponent implements OnChanges {
  @Output() onCancel = new EventEmitter();
  @Output() onSuccess = new EventEmitter();
  @Input({ required: true }) selectedProject: TProject;
  @Input({ required: true }) countries: TCountry[] = [];

  isLoading = signal(false);
  mode = signal<"create" | "update">("create");
  headerLabel = computed(() =>
    this.mode() === "create"
      ? "default-project.createProject"
      : "project-details.editProject"
  );

  defaultRegion: string = "DE";
  projectForm: FormGroup<ProjectForm>;

  constructor(
    public eventBus: NgEventBus,
    private toasterService: ToasterService,
    private sessionExpiredDialog: SessionExpiredService
  ) {
    this.projectForm = new FormGroup<ProjectForm>({
      projectName: new FormControl("", [
        Validators.required,
        Validators.maxLength(100),
      ]),
      country: new FormControl(""),
      projectDescription: new FormControl(""),
      customer: new FormControl(""),
      location: new FormControl(""),
      office: new FormControl(""),
      comment: new FormControl(""),
    });
  }

  ngOnChanges({ selectedProject, countries }: SimpleChanges): void {
    if (countries?.currentValue.length) this.setDefaultCountry();

    /** If the current value is defined, a selected project is passed, and it is intended to be updated, we set the mode to 'update' in this case. */
    if (selectedProject?.currentValue) {
      this.mode.set("update");
      const { projectId, createdAt, updatedAt, ...props } =
        this.selectedProject;
      this.projectForm.setValue(props);
    } else {
      this.mode.set("create");
      this.projectForm.reset();
      this.projectForm.get("country").setValue(this.defaultRegion);
    }
  }

  /**
   * Detects the browser language and region. If the user/browser region doesn't exist in our country list, we select germany as default.
   */
  setDefaultCountry() {
    if (window.navigator.languages.length > 0) {
      let browserLocaleWithRegion = window.navigator.languages.find(
        (lang) => new Intl.Locale(lang).region !== undefined
      );
      const { region } = new Intl.Locale(browserLocaleWithRegion);

      const countryIndex = this.countries.findIndex(
        ({ code }) => code === region
      );

      if (countryIndex > 0) this.defaultRegion = region;
      else this.defaultRegion = "DE";
    }
  }

  async onSubmit() {
    this.isLoading.set(true);

    // FIXME: Is this object mapping necessary? What fields does the backend expect?
    const project: ProjectInfo = {
      ...new ProjectInfo(),
      name: this.projectForm.value.projectName,
      projectDescription: {
        value: this.projectForm.value.projectDescription,
      },
      client: {
        value: this.projectForm.value.customer,
      },
      country: {
        value: this.projectForm.value.country,
      },
      designOffice: {
        value: this.projectForm.value.office,
      },
      location: {
        value: this.projectForm.value.location,
      },
      comment: {
        value: this.projectForm.value.comment,
      },
    };

    if (this.mode() === "create") {
      /** Create new project. */
      try {
        const result = await serviceFactory.ProjectService.addProject(project);
        this.onSuccess.emit();
        this.isLoading.set(false);

        /** Reset the form */
        this.ngOnChanges({
          selectedProject: new SimpleChange(
            undefined,
            this.selectedProject ?? undefined,
            false
          ),
        });

        // TODO: Add project name to success message.
        this.toasterService.showSuccess("toaster-message.projectAddSuccess");
      } catch (error) {
        if (error.response?.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          this.toasterService.showError("toaster-message.projectAddError");
        }
      }
    } else {
      /** Update existing project. */
      try {
        project.id = this.selectedProject.projectId;
        const result =
          await serviceFactory.ProjectService.updateProject(project);
        this.onSuccess.emit();
        this.isLoading.set(false);

        /** Reset the form */
        this.ngOnChanges({
          selectedProject: new SimpleChange(
            undefined,
            this.selectedProject ?? undefined,
            false
          ),
        });
        this.toasterService.showSuccess("toaster-message.projectUpdateSuccess");
      } catch (error) {
        if (error.response?.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          this.toasterService.showError("toaster-message.projectUpdateError");
        }
      }
    }
  }

  onCancelProject() {
    this.ngOnChanges({
      selectedProject: new SimpleChange(
        undefined,
        this.selectedProject ?? undefined,
        false
      ),
    });
    this.onCancel.emit();
  }
}
