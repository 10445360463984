import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { AxiosError } from "axios";
import { CookieService } from "ngx-cookie-service";
import DialogModel from "../../../app/models/common/DialogModel";
import { ConfirmationDialogComponent } from "../../shared/confirmation-dialog/confirmation-dialog.component";
import { ToasterService } from "../toaster.service";
import { serviceFactory } from "./servicefactory/serviceFactory";
@Injectable({
  providedIn: "root",
})
export class SessionExpiredService {
  constructor(
    private translator: TranslateService,
    private dialog: MatDialog,
    private cookieService: CookieService,
    private toasterService: ToasterService
  ) {}
  dialogModel = new DialogModel();

  openDialog() {
    this.dialogModel.content = "session-timeout-content";
    this.dialogModel.header = "session-timeout-header";
    this.dialogModel.actions = [
      {
        action: "OK",
        type: "primary",
      },
    ];
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "37.5rem",
      height: "13rem",
      data: this.dialogModel,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      await serviceFactory.ProjectService.logout()
        .then((response) => {
          this.cookieService.delete("JSESSIONID", "/");
          window.location.href = "/";
        })
        .catch((error: AxiosError) => {
          this.toasterService.showError(error.message);
          // }
        });
    });
  }
}
