export enum DriveProjectAttributeNames {
  PROJECT_DESCRIPTION = "Project Description",
  PLANNER = "Planner",
  DESIGN_OFFICE = "Design Office",
  LOCATION = "Location",
  CLIENT = "Client",
  STANDARD = "Standard",
  COUNTRY = "Country",
  LANGUAGE = "Language",
  COMMENT = "COMMENT",
}
