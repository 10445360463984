<div *ngIf="isShortCircuitLine">
  <div *ngFor="let prodVal of settingsRange">
    <div *ngFor="let settingValueScl of prodVal.currentTime" class="margin">
      <app-input-scl-mark
        [inputValue]="settingValueScl.inputValue"
        [inputLabel]="settingValueScl.inputLabel"
        [tranlatedTooltipValue]="
          settingValueScl.translatedMinValue +
          ' ... ' +
          settingValueScl.translatedMaxValue
        "
        (emitInputValue)="onSCLValueChange($event, settingValueScl)"
      ></app-input-scl-mark>
    </div>
  </div>
</div>

<div *ngIf="!isShortCircuitLine" class="d-flex w-100 mark-input" style="justify-content: space-between;">
  <app-input-scl-mark
    [inputValue]="markmodel.current"
    [inputLabel]="xLabel"
    [tranlatedTooltipValue]="tooltipXVal"
    [isMarkSelected]="true"
    [isCurrent]="true"
    [view]="markmodel.view"
    (emitInputValue)="onMarkXChange($event)"
    style="width: 48%;"
  ></app-input-scl-mark>
  <app-input-scl-mark
    [inputValue]="markmodel.time"
    [inputLabel]="yLabel"
    [tranlatedTooltipValue]="tooltipYVal"
    [isCurrent]="false"
    [view]="markmodel.view"
    [isMarkSelected]="true"
    (emitInputValue)="onMarkYChange($event)"
    style="width: 48%;"
  ></app-input-scl-mark>
</div>
