import { Component, Input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { NgEventBus } from "ng-event-bus";
import { RadioComponent } from "../../../../packages/ui/src/primitives/radio/radio.component";
import { EventType } from "../../models/constants/eventType";
import DeviceSettingsModel from "../../models/device-settings/DeviceSettingsModel";
@Component({
  selector: "app-setting-radio",
  templateUrl: "./setting-radio.component.html",
  styleUrls: ["./setting-radio.component.css"],
  standalone: true,
  imports: [TranslateModule, RadioComponent],
})
export class SettingRadioComponent {
  @Input() deviceSetting: DeviceSettingsModel;
  constructor(public eventBus: NgEventBus) {}
  onRadioSelection(characteristic: string) {
    let characteristicValue: DeviceSettingsModel = new DeviceSettingsModel();
    characteristicValue.curveType = this.deviceSetting.curveType;
    characteristicValue.selectedCharacteristic = characteristic;
    characteristicValue.productDetails = this.deviceSetting.productDetails;
    this.eventBus.cast(EventType.EMIT_RADIO_EVENT, characteristicValue);
  }
  isChecked(label: string): boolean {
    return label === this.deviceSetting.activeCharacteristic;
  }
}
