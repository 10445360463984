export const INFORMATIONCONSTANTS = [
  {
    displayName: "imp_corp_info_header",
    url: "corporateInfoUrl",
  },
  {
    displayName: "imp_privacy_header",
    url: "privacyPolicyUrl",
  },
  {
    displayName: "footer.cookiePolicy",
    url: "cookiePolicyUrl",
  },
  {
    displayName: "imp_term_use_header",
    url: "termsOfUserUrl",
  },
  {
    displayName: "footer.digitalID",
    url: "digitalIdUrl",
  },
];
