<div class="pe-3" [ngClass]="isDisable ? 'disabled' : ''">
  <label class="col-md-5 pad-2" [ngClass]="isDisable ? 'label--disabled' : ''">
    {{ inputlabel }} {{ units }}
  </label>
  <input
    ui-input
    step="any"
    [disabled]="isDisable"
    [(ngModel)]="sliderDetails.translatedInputValue"
    (change)="onValueChange($event.target.value)"
    [readonly]="sliderDetails.minValue === sliderDetails.maxValue"
    appTooltip
    tooltip="{{
      '[' +
        sliderDetails.translatedMinValue +
        ' ... ' +
        sliderDetails.translatedMaxValue +
        ']'
    }}"
    style="width: 100%;"
  />
</div>
