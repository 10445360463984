import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { AxiosError } from "axios";
import { NgEventBus } from "ng-event-bus";
import { take } from "rxjs";
import { AddMarkComponentComponent } from "../../add-mark-component/add-mark-component.component";
import DialogModel from "../../models/common/DialogModel";
import { ChartType } from "../../models/constants/chartType";
import { Constants } from "../../models/constants/Constants";
import { EventType } from "../../models/constants/eventType";
import MarkModel from "../../models/marks/MarkModel";
import { serviceFactory } from "../../services/serviceLayer/servicefactory/serviceFactory";
import { SessionExpiredService } from "../../services/serviceLayer/session-expired-dialog.service";
import { ToasterService } from "../../services/toaster.service";
import { ConfirmationDialogComponent } from "../../shared/confirmation-dialog/confirmation-dialog.component";

@Injectable({
  providedIn: "root",
})
export class MarksService {
  selectedViewMarksList: MarkModel[] = [];
  constructor(
    private dialog: MatDialog,
    public translator: TranslateService,
    private eventBus: NgEventBus,
    private toasterService: ToasterService,
    private sessionExpiredDialog: SessionExpiredService
  ) {}
  openAddMarkDialog(
    marksList: MarkModel[],
    x: number,
    y: number,
    chartType: ChartType,
    isMarkOnSeriesData: boolean,
    projectId: string,
    markOwnerIndex: number
  ) {
    if (marksList.length < Constants.MAX_MARKS_COUNT) {
      let markModel = serviceFactory.MarksHelper.createMarkModel(
        x,
        y,
        chartType,
        isMarkOnSeriesData,
        markOwnerIndex
      );
      const dialogRef = this.dialog.open(AddMarkComponentComponent, {
        width: "612px",
        height: "256px",
        data: markModel,
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe(async (updatedMark) => {
        if (updatedMark) {
          this.addMark(updatedMark, chartType, projectId);
        }
      });
    } else {
      this.openDialogForMaxEntry();
    }
  }
  openDialogForMaxEntry() {
    let replaceValue;
    let dialogModel = new DialogModel();

    this.translator
      .get("Action_add_maxentries_message")
      .pipe(take(1))
      .subscribe(
        (value: string) => (replaceValue = value.replace("{0}", "10"))
      );
    dialogModel.content = replaceValue;
    dialogModel.header = "Information";
    dialogModel.actions = [
      {
        action: "OK",
        type: "primary",
      },
    ];
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "37.5rem",
      height: "13rem",
      data: dialogModel,
    });
  }
  async addMark(mark: MarkModel, chartType: ChartType, projectId: string) {
    if (mark != null && mark.current != null && mark.time != null) {
      if (
        serviceFactory.MarksHelper.withinRange(
          mark.current,
          mark.time,
          chartType
        )
      ) {
        await serviceFactory.MarkService.addMark(projectId, mark)
          .then((markModel: MarkModel) => {
            this.selectedViewMarksList.push(markModel);
            this.eventBus.cast(
              EventType.UPDATED_MARK_LIST,
              this.selectedViewMarksList
            );
          })
          .catch((err: AxiosError) => {
            if (err?.response?.data === Constants.SESSIONEXPIRED) {
              this.sessionExpiredDialog.openDialog();
            } else {
              this.toasterService.showError(err?.message);
            }
          });
      } else {
        this.toasterService.showError("toaster-message.markNotWithinLimit");
      }
    }
  }

  async updateMark(selectedMark: MarkModel, projectId: string) {
    await serviceFactory.MarkService.updatemark(
      selectedMark,
      selectedMark.index,
      projectId
    )
      .then((updatedMark: MarkModel) => {
        if (updatedMark) {
          updatedMark.translatedLabel = updatedMark.label;
          const indexToUpdate = this.selectedViewMarksList.findIndex(
            (mark) => mark.id === updatedMark.id
          );
          if (indexToUpdate !== -1) {
            this.selectedViewMarksList[indexToUpdate] = updatedMark;
            this.eventBus.cast(EventType.CONFIRM_DELETE_MARK, updatedMark);
            this.eventBus.cast(EventType.ADD_MARK_IN_GRAPH, updatedMark);
          }
        }
        // this.eventBus.cast(
        //   EventType.UPDATED_MARK_LIST,
        //   this.selectedViewMarksList
        // );
        this.eventBus.cast(
          EventType.HIGHLIGHT_SELECTED_MARK,
          selectedMark.index
        );
      })
      .catch((err: AxiosError) => {
        if (err?.response?.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          this.toasterService.showError(err?.message);
          // this.selectedMark.rgbValue = this.prevMarkColor;
          // this.eventBus.cast(EventType.ON_MARK_COLORCHANGE, this.selectedMark);
        }
      });
  }
}
