<ng-container>
  <button
    type="button"
    role="switch"
    [attr.aria-checked]="_checked"
    [attr.data-state]="_checked"
    (click)="_toggle()"
    [disabled]="_disabled"
  >
    <span class="thumb" [attr.data-state]="_checked"></span>
    <input
      aria-hidden="true"
      tabindex="-1"
      type="checkbox"
      [(ngModel)]="_checked"
      (blur)="_onTouched()"
    />
  </button>
</ng-container>
