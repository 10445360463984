import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-edit-icon",
    templateUrl: "./edit-icon.component.html",
    standalone: true,
})
export class EditIconComponent {
  constructor() {}
}
