<div
  class="height-100 w-100"
  (mouseleave)="emitSliderChangesOnMouseLeave($event)"
>
  <ngx-slider
    [hidden]="sliderDetails.type === 'HORIZONTAL_MULTIPLIER'"
    [ngClass]="isDisableSlider ? 'slider-disabled' : ''"
    [(value)]="sliderDetails.inputIndex"
    [options]="options"
    (userChange)="valueChanged($event)"
  ></ngx-slider>
</div>
