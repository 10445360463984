import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgEventBus } from "ng-event-bus";
import { environment } from "../../../src/environments/environment";
import { EventType } from "../models/constants/eventType";
import { SessionExpiredService } from "./serviceLayer/session-expired-dialog.service";

@Injectable()
export class SessionTimerService {
  _timeoutSeconds: number;
  timerSubscription;
  startTime = new Date();
  bufferTime: number = 60;
  sessionTimeOut: number = environment.sessionTime;
  constructor(
    private eventBus: NgEventBus,
    private sessionExpiredDialog: SessionExpiredService,
    private router: Router
  ) {}

  startTimer() {
    this.stopTimer();
    this.timerSubscription = setInterval((_) => {
      let currentTime = new Date();
      let idleTime = +currentTime - +this.startTime;
      this._timeoutSeconds = this.sessionTimeOut - Math.floor(idleTime / 1000);
      if (this._timeoutSeconds === 0 || this._timeoutSeconds < 0) {
        this._timeoutSeconds = 0;
        this.stopTimer();
        this.sessionExpiredDialog.openDialog();
        this.eventBus.cast(EventType.SESSIONTIMEDOUT, true);
      } else if (
        this._timeoutSeconds === this.bufferTime &&
        this.router.url.includes("/projects/")
      ) {
        this.eventBus.cast(EventType.AUTOSAVEONSESSIONEXPIRATION, false);
      }
    }, 1000);
  }

  stopTimer() {
    if (this.timerSubscription) {
      clearInterval(this.timerSubscription);
    }
  }

  resetTimer() {
    this.startTime = new Date();
  }
}
