import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-search-icon",
  templateUrl: "./search-icon.component.html",
  styles: [":host {display: grid; place-content: center;}"],
  standalone: true,
})
export class SearchIconComponent {
  constructor() {}
}
