import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-scl-icon",
    templateUrl: "./scl-icon.component.html",
    standalone: true,
})
export class SclIconComponent {
  constructor() {}
}
