import {
  ActivatedRouteSnapshot,
  CanActivate,
  GuardResult,
  RedirectCommand,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { mergeMap, map, catchError } from "rxjs/operators";

/**
 * Used to check whether a user is already logged in. If so, the user gets redirected to the home page.
 */
@Injectable({
  providedIn: "root",
})
export class LoginGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService
  ) {}

  loginRedirectUrl = this.router.parseUrl("/login");
  errorRedirectUrl = this.router.parseUrl("/notregistered");

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<GuardResult> {
    this.loginRedirectUrl.queryParams = { returnUrl: state.url };

    if (!next.queryParams.isNotAuthenticated) {
      return this.auth.user$.pipe(
        mergeMap((user) => {
          /**
           * User is authenticated with SiemensID.
           * Redirect to index page.
           */
          if (user) {
            return of(
              new RedirectCommand(this.router.parseUrl("/"), {
                skipLocationChange: false,
              })
            );
          } else {
            /**
             * User is _not_ authenticated with SiemensID.
             * Do nothing.
             */
            return this.auth.getAccessTokenSilently().pipe(
              map((token) => {
                /**
                 * If a token is returned, this.auth.user is available.
                 */
                if (token) {
                  return new RedirectCommand(this.router.parseUrl("/"), {
                    skipLocationChange: false,
                  });
                }
              }),
              catchError(() => {
                return of(true);
              })
            );
          }
        })
      );
    } else {
      return of(true);
    }
  }
}
