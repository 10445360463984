import { OverlayModule } from "@angular/cdk/overlay";
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import {
  ApplicationConfig,
  APP_INITIALIZER,
  importProvidersFrom,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter } from "@angular/router";
import { JwtModule } from "@auth0/angular-jwt";
import {
  AuthGuard,
  AuthModule,
  AuthService,
  AuthState,
} from "@auth0/auth0-angular";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from "@ngx-translate/core";
import { ChartModule } from "angular-highcharts";
import axios from "axios";
import { NgEventBus } from "ng-event-bus";
import { ColorPickerModule } from "ngx-color-picker";
import { FileValidators } from "ngx-file-drag-drop";
import { ToastrModule } from "ngx-toastr";
import {
  catchError,
  filter,
  first,
  mapTo,
  mergeMap,
  Observable,
  of,
  throwError,
} from "rxjs";
import { DeviceCatalogModule } from "src/app/device-catalog-component/device-catalog.module";
import { DeviceSettingsModule } from "src/app/device-settings-component/device-settings.module";
import { SaveProjectGuard } from "src/app/guards/save-project.guard";
import { CustomLoader } from "src/app/language/custom.loader";
import { LanguageMissingTranslationHandler } from "src/app/language/language.missing-translation-handler";
import { ConfirmationEventService } from "src/app/services/ConfirmationEventServices";
import ProductCatalogService from "src/app/services/serviceLayer/ProductCatalogService";
import ProductService from "src/app/services/serviceLayer/ProductService";
import { SessionTimerService } from "src/app/services/session-timer.service";
import { SharedModule } from "src/app/shared/shared.module";
import { fromRoot } from "src/app/store";
import { environment } from "src/environments/environment";
import { routes } from "./app.routes";

function initializeAxios(
  authService: AuthService,
  authState: AuthState
): () => Observable<any> {
  // ugly code adapted from auth0-angular AuthHttpInterceptor to forward bearer token
  const waitUntil = (signal$) => (source$) =>
    source$.pipe(mergeMap((value) => signal$.pipe(first(), mapTo(value))));
  return () => {
    axios.interceptors.request.use(
      (config) => {
        const isLoaded$ = authService.isLoading$.pipe(
          filter((isLoading) => !isLoading)
        );
        return of(config)
          .pipe(waitUntil(isLoaded$))
          .toPromise()
          .then((_) =>
            authService
              .getAccessTokenSilently()
              .pipe(
                catchError((err) => {
                  authState.setError(err);
                  return throwError(err);
                })
              )
              .toPromise()
          )
          .then((token) => {
            config.headers["Authorization"] = `Bearer ${token}`;
            return config;
          });
      },
      (error) => {
        console.log("error in axios response");
        return Promise.reject(error);
      }
    );
    return null;
  };
}
export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    importProvidersFrom(
      BrowserModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: CustomLoader,
          deps: [HttpClient],
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: LanguageMissingTranslationHandler,
        },
      }),
      SharedModule,
      ChartModule,
      OverlayModule,
      ColorPickerModule,
      FileValidators,
      DeviceCatalogModule,
      DeviceSettingsModule,
      NgIdleKeepaliveModule.forRoot(),
      ToastrModule.forRoot({
        timeOut: 5000,
        positionClass: "toast-top-right",
        preventDuplicates: true,
        closeButton: true,
      }),
      EffectsModule.forRoot([fromRoot.ProductEffects]),
      StoreModule.forRoot({
        productState: fromRoot.productReducer,
        userState: fromRoot.userReducer,
        countryState: fromRoot.countryReducer,
      }),
      JwtModule.forRoot({
        config: {
          tokenGetter: () => "",
          allowedDomains: [],
          disallowedRoutes: [],
        },
      }),
      AuthModule.forRoot(
        // XXX cast to any required as a workaround since 'authorizationParams.display' is typed according to auth0 api but this parameter
        // is used a string by Siemens Universal Login Page (see https://wiki.siemens.com/display/en/Universal+Login+Page)
        {
          ...environment.auth,
        } as any
      )
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAxios,
      deps: [AuthService, AuthState],
      multi: true,
    },
    ConfirmationEventService,
    NgEventBus,
    ProductService,
    ProductCatalogService,
    SaveProjectGuard,
    AuthGuard,
    SessionTimerService,
    {
      provide: Window,
      useValue: window,
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
};
