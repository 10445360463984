import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from "@ngx-translate/core";
import { CustomLoader } from "../language/custom.loader";
import { LanguageMissingTranslationHandler } from "../language/language.missing-translation-handler";
import { ComponentsHeaderComponent } from "./components-header/components-header.component";
import { SpinnerComponent } from "./spinner/spinner.component";
import { TooltipDirective } from "./tooltip.directive";
import { TranslateNumberPipe } from "./translate-number.pipe";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: LanguageMissingTranslationHandler,
      },
    }),
    SpinnerComponent,
    ComponentsHeaderComponent,
    TranslateNumberPipe,
    TooltipDirective,
  ],
  exports: [
    SpinnerComponent,
    ComponentsHeaderComponent,
    TranslateNumberPipe,
    TooltipDirective,
  ],
  providers: [],
  bootstrap: [],
})
export class CommonHelperModule {}
