import { Component, Input } from "@angular/core";
import BaseModel from "../../models/devices/BaseModel";
import { TranslateNumberPipe } from "../../common/translate-number.pipe";
import { NgIf, NgFor } from "@angular/common";

@Component({
    selector: "app-device-properties",
    templateUrl: "./device-properties.component.html",
    styleUrls: ["./device-properties.component.css"],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        TranslateNumberPipe,
    ],
})
export class DevicePropertiesComponent {
  @Input() selectedProduct: BaseModel;

  constructor() {}
}
