import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { NgEventBus } from "ng-event-bus";
import CatalogDialogModel from "../../models/catalog/CatalogDialogModel";
import SpinnerModel from "../../models/common/SpinnerModel";
import { TranslateModule } from "@ngx-translate/core";
import { SpinnerComponent } from "../../common/spinner/spinner.component";
import { ProductAttributesComponent } from "../product-attributes/product-attributes.component";
import { ProductCatalogTreeComponent } from "../product-catalog-tree/product-catalog-tree.component";
import { IconsComponent } from "../../icons/icons.component";
import { NgClass, NgIf } from "@angular/common";
import { ButtonComponent } from "../../../../packages/ui/src/primitives/button/button.component";
import { CloseIconComponent } from "../../icons/close-icon/close-icon.component";

@Component({
  selector: "app-device-catalog-dialog",
  templateUrl: "./device-catalog-dialog.component.html",
  styleUrls: ["./device-catalog-dialog.component.css"],
  standalone: true,
  imports: [
    NgClass,
    ProductCatalogTreeComponent,
    ProductAttributesComponent,
    NgIf,
    SpinnerComponent,
    TranslateModule,
    ButtonComponent,
    CloseIconComponent,
  ],
})
export class DeviceCatalogDialogComponent implements OnInit {
  selectedTab: number = 0;
  searchedText: string;
  header: string = "curves-view.addDevice";
  spinnerModel = new SpinnerModel();

  constructor(
    public dialogRef: MatDialogRef<DeviceCatalogDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CatalogDialogModel,
    public eventBus: NgEventBus
  ) {}

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.header = this.data.isEditDevice
      ? "curves-view.editDevice"
      : "curves-view.addDevice";
  }
  // onTabClick(tabChangeEvent: MatTabChangeEvent) {
  //   this.selectedTab = tabChangeEvent.index;
  // }
  close() {
    this.dialogRef.close();
  }
  showSpinner(spinner: SpinnerModel) {
    this.spinnerModel = spinner;
  }
}
