import { Component, Input } from "@angular/core";

@Component({
  standalone: true,
  selector: "button[ui-button]",
  styleUrl: "./button.component.css",
  template: "<ng-content />",
  host: {
    "[class]": "[variant, size]",
  },
})
export class ButtonComponent {
  @Input() size: "icon" | "small" | "large" | "icon-small" = "large";
  @Input() variant: "primary" | "secondary" | "tertiary" | "critical" =
    "primary";
}
