import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-document-icon",
    templateUrl: "./document-icon.component.html",
    standalone: true,
})
export class DocumentIconComponent {
  constructor() {}
}
