import { Component } from "@angular/core";

@Component({
  selector: "input[ui-radio]",
  standalone: true,
  styleUrl: "./radio.component.css",
  template: "",
  host: {
    role: "radio",
  },
})
export class RadioComponent {}
