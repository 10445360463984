<div class="d-flex flex-column align-items-center justify-content-between m-3">
  <div class="w-100 d-flex justify-content-between">
    <button
      ui-button
      size="icon-small"
      variant="tertiary"
      (click)="collapseSideBar()"
      appTooltip
      tooltip="{{ 'tooltip.collapseBar' | translate }}"
    >
      <app-arrow-icon [type]="'left'"></app-arrow-icon>
    </button>
    <div class="d-flex">
      <div class="deviceMark-color">
        <div
          *ngIf="
            !isMarkSelected &&
            selectedProduct &&
            selectedProduct.protectiveDevice
          "
          data-testid="deviceColor-SettingsBar"
          [style.background-color]="selectedProduct.protectiveDevice.color"
          class="col-md-3 settings-view__device-clr"
        ></div>
        <!-- Marks -->
        <div
          *ngIf="isMarkSelected && selectedMark"
          data-testid="markColor-SettingsBar"
          [style.background-color]="selectedMark.rgbValue"
          class="col-md-3 settings-view__device-clr"
        ></div>
      </div>
      <button
        ui-button
        size="icon-small"
        variant="tertiary"
        data-testid="hideDevice"
        (click)="showHideDevice()"
        appTooltip
        tooltip="{{ 'tooltip.hideDevice' | translate }}"
        *ngIf="
          (!isMarkSelected &&
            selectedProduct &&
            selectedProduct.product &&
            selectedProduct.product.toggleSlashEye) ||
          (isMarkSelected && selectedMark.visible)
        "
      >
        <app-eye-icon [type]="'open'"></app-eye-icon>
      </button>
      <button
        ui-button
        size="icon-small"
        variant="tertiary"
        data-testid="unHideDevice"
        (click)="showHideDevice()"
        appTooltip
        tooltip="{{ 'tooltip.unHideDevice' | translate }}"
        *ngIf="
          (!isMarkSelected &&
            selectedProduct &&
            selectedProduct.product &&
            !selectedProduct.product.toggleSlashEye) ||
          (isMarkSelected && !selectedMark.visible)
        "
      >
        <app-eye-icon [type]="'close'"></app-eye-icon>
      </button>
      <button
        ui-button
        style="color: var(--btn-delete-color);"
        size="icon-small"
        variant="tertiary"
        data-testid="deleteDevice"
        (click)="deleteDevice()"
        appTooltip
        tooltip="{{ 'Action_remove' | translate }}"
        *ngIf="(selectedProduct && selectedProduct.product) || selectedMark"
      >
        <app-delete-icon />
      </button>
      <button
        ui-button
        size="icon-small"
        variant="tertiary"
        data-testid="editDevice"
        (click)="onEditProductDetails()"
        appTooltip
        tooltip="{{ 'headers.editDevice' | translate }}"
        *ngIf="
          !isMarkSelected &&
          selectedProduct &&
          selectedProduct.product &&
          selectedProduct.product.type !== 'shortcircuitline'
        "
      >
        <app-edit-icon />
      </button>
    </div>
  </div>
  <div class="w-100 d-flex flex-column">
    <strong class="fc-gray">{{ settingsHeader | translate }}</strong>
    <div
      #deviceName
      data-testid="editDeviceLabelInput"
      class="edit-label"
      *ngIf="!isMarkSelected"
    >
      <input
        ui-input
        class="w-100"
        id="editDeviceLabelInput"
        *ngIf="!isMarkSelected"
        [(ngModel)]="productNameLabel"
        maxlength="100"
        ui-input
        (focusout)="editDeviceLabel()"
        (keyup.enter)="editDeviceLabel()"
      />
    </div>

    <!-- Marks -->
    <div #markName class="edit-label">
      <input
        ui-input
        id="editMarkLabelInput"
        data-testid="editMarkLabelInput"
        *ngIf="isMarkSelected"
        class="w-100"
        [(ngModel)]="markNameLabel"
        ui-input
        (focusout)="editmarkLabel()"
        (keyup.enter)="editmarkLabel()"
        maxlength="100"
      />
    </div>
  </div>
</div>
