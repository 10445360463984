import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { AxiosError } from "axios";
import { NgEventBus } from "ng-event-bus";
import { Constants } from "../../../models/constants/Constants";
import { EventType } from "../../../models/constants/eventType";
import ProjectInfo from "../../../models/drive/projectInfo";
import SpinnerModel from "../../../models/common/SpinnerModel";
import { serviceFactory } from "../../../services/serviceLayer/servicefactory/serviceFactory";
import { SessionExpiredService } from "../../../services/serviceLayer/session-expired-dialog.service";
import { ToasterService } from "../../../services/toaster.service";
import { TranslateModule } from "@ngx-translate/core";
import { SpinnerComponent } from "../../../common/spinner/spinner.component";
import { ButtonComponent } from "../../../../../packages/ui/src/primitives/button/button.component";
import { CloseIconComponent } from "../../../icons/close-icon/close-icon.component";

@Component({
  selector: "app-file-upload",
  templateUrl: "./upload.component.html",
  styleUrls: ["./upload.component.css"],
  standalone: true,
  imports: [
    SpinnerComponent,
    TranslateModule,
    ButtonComponent,
    CloseIconComponent,
  ],
})
export class UploadComponent {
  projectId: string;
  file: File = null;
  isFileEmpty: boolean = false;
  invalidFile: boolean = false;
  selectedFileName: string | null = null;
  spinnerModel = new SpinnerModel();

  constructor(
    public eventBus: NgEventBus,
    public dialogRef: MatDialogRef<UploadComponent>,
    private toasterService: ToasterService,
    private sessionExpiredDialog: SessionExpiredService
  ) {}

  async onUpload() {
    const isReadable = await this.checkFileReadability(this.file);
    if (!isReadable) {
      let translatedErrorMsg = this.toasterService.toasterMessage(
        "toaster-message.fileWriteProtected"
      );
      this.toasterService.showError(translatedErrorMsg);
      return;
    }
    this.spinnerModel.show = true;
    this.spinnerModel.text = "loader.uploadProject";
    await serviceFactory.ProjectService.uploadFile(this.file, "1")
      .then(async (response: ProjectInfo) => {
        this.projectId = response.projectId;
        this.eventBus.cast(EventType.REFRESH_PROJECTLIST, true);
        this.spinnerModel.show = false;
        this.dialogRef.close(true);
        this.toasterService.showSuccess("toaster-message.fileUploadSuccess");
      })
      .catch((e: AxiosError) => {
        if (e.response.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          this.toasterService.showError(e.message);
        }
        this.spinnerModel.show = false;
        this.dialogRef.close();
      });
  }

  checkFileReadability(file: File): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        resolve(true);
      };
      fileReader.onerror = () => {
        resolve(false);
      };
      fileReader.readAsDataURL(file);
    });
  }

  getfile(event: any) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.isFileEmpty = false;
      this.selectedFileName = this.file ? this.file.name : null;
      if (this.file.name.endsWith(".scu")) {
        this.invalidFile = true;
      } else {
        this.invalidFile = false;
        this.toasterService.showError(
          this.toasterService.toasterMessage(
            "project-definition.upload_invalidFormat"
          )
        );
      }
    } else {
      this.isFileEmpty = true;
    }
  }

  handleDrop(event: DragEvent) {
    event.preventDefault();
    this.file = event.dataTransfer?.files[0];
    if (this.file != null || this.file != undefined) {
      this.selectedFileName = this.file ? this.file.name : null;
      if (this.file.name.endsWith(".scu")) {
        this.invalidFile = true;
      } else {
        this.invalidFile = false;
        this.toasterService.showError(
          this.toasterService.toasterMessage(
            "project-definition.upload_invalidFormat"
          )
        );
      }
    } else {
      this.isFileEmpty = true;
    }
  }

  handleDragOver(event: DragEvent) {
    event.preventDefault();
  }

  removeFile() {
    this.file = null;
    this.isFileEmpty = true;
    this.selectedFileName = null;
  }
}
