<span [ngSwitch]="iconName" style="display: inline-grid; place-content: center">
  <app-info-icon *ngSwitchCase="'info'"></app-info-icon>
  <app-curves-icon *ngSwitchCase="'curves'"></app-curves-icon>
  <app-home-icon *ngSwitchCase="'home'"></app-home-icon>
  <app-plus-icon *ngSwitchCase="'plus'"></app-plus-icon>
  <app-upload-icon *ngSwitchCase="'upload'"></app-upload-icon>
  <app-edit-icon *ngSwitchCase="'edit'"></app-edit-icon>
  <app-download-icon *ngSwitchCase="'download'"></app-download-icon>
  <app-delete-icon *ngSwitchCase="'delete'"></app-delete-icon>
  <app-close-icon
    *ngSwitchCase="'close'"
    [height]="height"
    [width]="width"
  ></app-close-icon>
  <app-move-up-down *ngSwitchCase="'move'" [type]="type"></app-move-up-down>
  <app-scl-icon *ngSwitchCase="'scl'"></app-scl-icon>
  <app-arrow-icon *ngSwitchCase="'arrow'" [type]="type"></app-arrow-icon>
  <app-folder-icon *ngSwitchCase="'folder'" [type]="type"></app-folder-icon>
  <app-document-icon *ngSwitchCase="'document'"></app-document-icon>
  <app-reset-icon *ngSwitchCase="'reset'"></app-reset-icon>
  <app-globe-icon *ngSwitchCase="'language'"></app-globe-icon>
  <app-link-icon *ngSwitchCase="'link'" [type]="type"></app-link-icon>
  <app-eye-icon *ngSwitchCase="'eye'" [type]="type"></app-eye-icon>
  <app-properties-icon *ngSwitchCase="'properties'"></app-properties-icon>
  <app-setting-icon *ngSwitchCase="'settings'"></app-setting-icon>
  <app-logout-icon *ngSwitchCase="'logout'"></app-logout-icon>
  <app-search-icon *ngSwitchCase="'searchIcon'"></app-search-icon>
  <app-small-close-icon *ngSwitchCase="'small-close'"></app-small-close-icon>
</span>
