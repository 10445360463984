import { NgClass, NgFor, NgIf } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatOption } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatSelect } from "@angular/material/select";
import { TranslateModule } from "@ngx-translate/core";
import { AxiosError } from "axios";
import { NgEventBus } from "ng-event-bus";
import { MetaData } from "ng-event-bus/lib/meta-data";
import { Subscription } from "rxjs";
import { ButtonComponent } from "../../../../packages/ui/src/primitives/button/button.component";
import { InputComponent } from "../../../../packages/ui/src/primitives/input/input.component";
import { TooltipDirective } from "../../common/tooltip.directive";
import { ResetIconComponent } from "../../icons/reset-icon/reset-icon.component";
import { CatalogAttributesCache } from "../../models/catalog/CatalogAttributesCache";
import { CatalogAttributeModel } from "../../models/catalog/CatalogAttributesModel";
import { CatalogAttributeValueModel } from "../../models/catalog/CatalogAttributeValueModel";
import CatalogEnvironmentVariables from "../../models/catalog/CatalogEnvironmentVariables";
import { ProductNode } from "../../models/catalog/CatalogProductNodeModel";
import DialogModel from "../../models/common/DialogModel";
import SpinnerModel from "../../models/common/SpinnerModel";
import { Constants } from "../../models/constants/Constants";
import { EventType } from "../../models/constants/eventType";
import BaseModel from "../../models/devices/BaseModel";
import ProductModel from "../../models/devices/ProductModel";
import ProjectInfo from "../../models/drive/projectInfo";
import { serviceFactory } from "../../services/serviceLayer/servicefactory/serviceFactory";
import { SessionExpiredService } from "../../services/serviceLayer/session-expired-dialog.service";
import { ToasterService } from "../../services/toaster.service";
import { ConfirmationDialogComponent } from "../../shared/confirmation-dialog/confirmation-dialog.component";
@Component({
  selector: "app-product-attributes",
  templateUrl: "./product-attributes.component.html",
  styleUrls: ["./product-attributes.component.css"],
  standalone: true,
  imports: [
    NgIf,
    TooltipDirective,
    NgFor,
    MatLabel,
    MatFormField,
    MatSelect,
    FormsModule,
    MatOption,
    NgClass,
    TranslateModule,
    InputComponent,
    ButtonComponent,
    ResetIconComponent,
  ],
})
export class ProductAttributesComponent implements OnDestroy {
  data = new Array<CatalogAttributeModel>();
  selectedNode: ProductNode = new ProductNode();
  catalogEnvironmentVariables: CatalogEnvironmentVariables;
  productAttributesCache = new CatalogAttributesCache();
  isValidOrderNumber: boolean = false;
  mode: string = "add_device";
  productToBeEdited: ProductModel = new ProductModel();
  subscriptions: Subscription = new Subscription();
  readonly CONSTANT = Constants;
  @Input() projectInfo: ProjectInfo;
  @Output() closeCatalog = new EventEmitter();
  @Output() showSpinner = new EventEmitter();
  spinnerModel = new SpinnerModel();
  isGetMlfbCompleted: boolean = true;
  productImgUrl: string = "";

  constructor(
    public eventBus: NgEventBus,
    private sessionExpiredDialog: SessionExpiredService,
    private toasterService: ToasterService,
    public dialog: MatDialog
  ) {
    const onLeafNodeClick = this.eventBus
      .on(EventType.ON_LEAFNODE_CLICK)
      .subscribe(async (metadata: MetaData) => {
        this.spinnerModel.show = true;
        this.spinnerModel.text = "loader.loadCatalogAttributes";
        this.showSpinner.emit(this.spinnerModel);
        this.selectedNode = metadata.data;
        this.productAttributesCache.productGroup =
          this.selectedNode.product_group;
        this.data = [];
        this.selectedNode.isSearched ? "" : this.resetAttributes();
        this.isValidOrderNumber = false;
        await this.getProductAttributes();
        this.getProductImageUrl();
      });
    const SEARCHPRODUCTFROMCATALOG = this.eventBus
      .on(EventType.SEARCHPRODUCTFROMCATALOG)
      .subscribe((metadata: MetaData) => {
        this.selectedNode = metadata.data;
        this.data = [];
      });
    const onParentNodeClick = this.eventBus
      .on(EventType.ON_PARENTNODE_CLICK)
      .subscribe((metadata: MetaData) => {
        this.data = [];
        this.selectedNode = metadata.data;
      });
    const onEditProduct = this.eventBus
      .on(EventType.ONEDITPRODUCTDETAILS)
      .subscribe(async (metaData: MetaData) => {
        this.productToBeEdited = metaData.data;
        this.mode = "CatalogFavoriteListControl_CONFIRM";
        //To show spinner
        this.spinnerModel.show = true;
        this.spinnerModel.text = "loader.loadCatalogAttributes";
        this.showSpinner.emit(this.spinnerModel);
        await this.getProductAttributesForOrderNumber();
        this.getProductImageUrl();

      });
    const onOpenAddDeviceBar = this.eventBus
      .on(EventType.OPENADDDEVICESIDEBAR)
      .subscribe(async (metaData: MetaData) => {
        this.mode = "add_device";
        this.productToBeEdited = new ProductModel();
        this.data = [];
        this.selectedNode = new ProductNode();
      });
    this.subscriptions.add(onLeafNodeClick);
    this.subscriptions.add(onParentNodeClick);
    this.subscriptions.add(onEditProduct);
    this.subscriptions.add(onOpenAddDeviceBar);
    this.subscriptions.add(SEARCHPRODUCTFROMCATALOG);
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  openWarning() {
    let dialogModel = new DialogModel();

    dialogModel.content = "DlgProductNotFoundMessage";
    dialogModel.header = this.productToBeEdited.product.name;
    dialogModel.actions = [
      {
        action: "OK",
        type: "primary",
      },
    ];
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "37.5rem",
      height: "13rem",
      data: dialogModel,
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.closeCatalog.emit(true);
      }
    });
  }
  async addOrderNumber() {
    if (this.mode === "add_device") {
      let product: BaseModel = new BaseModel();
      product.name = this.catalogEnvironmentVariables.mlfb.toString();
      product.productGroup = this.selectedNode.product_group;
      product.ambientTemparature =
        this.catalogEnvironmentVariables.ambientTemperature;
      product.nominalVoltage = this.catalogEnvironmentVariables.ratedVoltage;
      this.eventBus.cast(EventType.ADD_PRODUCT_FROM_PRODUCTCATALOG, product);
      this.resetAttributesData();
    } else {
      //TODO::Folder Id Removal Migration
      await serviceFactory.ProductCatalogService.updateOrderNumber(
        false,
        this.productToBeEdited.product.name,
        this.catalogEnvironmentVariables.mlfb.toString(),
        "",
        this.projectInfo.id,
        this.productToBeEdited.protectiveDevice.color,
        this.productToBeEdited.protectiveDevice.lineWidth,
        this.productToBeEdited.protectiveDevice.lineStyle,
        this.productToBeEdited.facadeListEntry.listEntry.index,
        this.catalogEnvironmentVariables.ratedVoltage,
        this.catalogEnvironmentVariables.ambientTemperature
      )
        .then((res) => {
          this.eventBus.cast(EventType.UPDATEDORDERNUMBERDETAILS, res);
        })
        .catch((error) => {
          if (error.response.data === Constants.SESSIONEXPIRED) {
            this.sessionExpiredDialog.openDialog();
          } else {
            this.toasterService.showError(error.message);
          }
        });
    }
    this.closeCatalog.emit(true);
  }
  async resetAttributes() {
    this.isGetMlfbCompleted = false;
    await serviceFactory.ProductCatalogService.resetProductAttributes(
      this.selectedNode.product_group
    )
      .then(async (res) => {
        // this.productAttributesCache.selectedValueMap.clear();
        this.data = res;
        let attributesNotFiled = this.data.filter(
          (x) => x.requested_value.value == ""
        );
        await serviceFactory.ProductCatalogService.getMlfb(
          this.selectedNode.product_group
        ).then((res) => {
          this.catalogEnvironmentVariables = res;
          this.isValidOrderNumber =
            this.catalogEnvironmentVariables.mlfb.length > 0 &&
            !this.catalogEnvironmentVariables.mlfb.includes("_") &&
            attributesNotFiled.length === 0
              ? true
              : false;
          this.isGetMlfbCompleted = true;
        });
      })
      .catch((error: AxiosError) => {
        if (error.response.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          this.toasterService.showError(error.message);
        }
      });
  }
  resetAttributesData() {
    this.productAttributesCache.selectedValueMap.clear();
    this.data = [];
    this.selectedNode = null;
  }

  onCancelCatalog() {
    this.resetAttributesData();
    this.closeCatalog.emit(true);
  }
  async onAttributesSelection(
    selectedValue: CatalogAttributeModel,
    value: CatalogAttributeValueModel
  ) {
    selectedValue.requested_value.value = value.value;
    selectedValue.requested_value.displayValue = value.displayValue;
    let attributesNotFiled;
    this.productAttributesCache.selectedValueMap.set(
      selectedValue.key,
      selectedValue.requested_value
    );
    this.isGetMlfbCompleted = false;
    // this.showSpinner = true;
    await serviceFactory.ProductCatalogService.postProductAttributes(
      this.selectedNode.product_group,
      selectedValue
    )
      .then(async (res) => {
        this.data = res;
        attributesNotFiled = this.data.filter(
          (x) => x.requested_value.value == ""
        );
        await serviceFactory.ProductCatalogService.getMlfb(
          this.selectedNode.product_group
        ).then((res) => {
          this.catalogEnvironmentVariables = res;
          this.isValidOrderNumber =
            this.catalogEnvironmentVariables.mlfb.length > 0 &&
            !this.catalogEnvironmentVariables.mlfb.includes("_") &&
            attributesNotFiled.length === 0
              ? true
              : false;
          this.isGetMlfbCompleted = true;
          // this.showSpinner = false;
        });
      })
      .catch((error: AxiosError) => {
        if (error.response.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          this.toasterService.showError(error.message);
        }
      });
  }

  setRequestedValue(response: CatalogAttributeModel[]) {
    if (response && response.length > 0) {
      this.productAttributesCache.selectedValueMap.forEach(
        (value: CatalogAttributeValueModel, key: string) => {
          response.find((val) => val.key === key).requested_value = value;
        }
      );
      return response;
    }
  }

  async getProductAttributes() {
    let attributesNotFiled;
    await serviceFactory.ProductCatalogService.getProductAttributes(
      this.selectedNode.product_group
    )
      .then(async (res: CatalogAttributeModel[]) => {
        this.data = res;
        attributesNotFiled = this.data.filter(
          (x) => x.requested_value.value == ""
        );
        this.catalogEnvironmentVariables =
          await serviceFactory.ProductCatalogService.getMlfb(
            this.selectedNode.product_group
          );
        this.isValidOrderNumber =
          this.catalogEnvironmentVariables.mlfb.length > 0 &&
          !this.catalogEnvironmentVariables.mlfb.includes("_") &&
          attributesNotFiled.length === 0
            ? true
            : false;
        this.isGetMlfbCompleted = true;
      })
      .catch((error: AxiosError) => {
        if (error.response.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          this.toasterService.showError(error.message);
        }
      })
      .finally(() => {
        this.spinnerModel.show = false;
        this.showSpinner.emit(this.spinnerModel);
      });
  }

  async getProductAttributesForOrderNumber() {
    await serviceFactory.ProductCatalogService.getProductAttributesForOrderNumber(
      "",
      this.projectInfo.id,
      this.productToBeEdited.facadeListEntry.listEntry.index,
      false
    )
      .then(async (res: CatalogAttributeModel[]) => {
        if (res.length > 0) {
          this.data = res;
          let attributesNotFiled = this.data.filter(
            (x) => x.requested_value.value == ""
          );
          this.catalogEnvironmentVariables =
            await serviceFactory.ProductCatalogService.getMlfb(
              this.selectedNode.product_group
            );
          this.isValidOrderNumber =
            this.catalogEnvironmentVariables.mlfb.length > 0 &&
            !this.catalogEnvironmentVariables.mlfb.includes("_") &&
            attributesNotFiled.length === 0
              ? true
              : false;
        } else {
          this.openWarning();
        }
      })
      .catch((error: AxiosError) => {
        if (error.response.data === Constants.SESSIONEXPIRED) {
          this.sessionExpiredDialog.openDialog();
        } else {
          this.toasterService.showError(error.message);
        }
      })
      .finally(() => {
        this.spinnerModel.show = false;
        this.showSpinner.emit(this.spinnerModel);
      });
  }
  getProductImageUrl() {
    this.productImgUrl = "";
    if (this.selectedNode.img_url) {
      this.checkIfImageExists(
        this.productImgUrl
          .concat(Constants.CATALOG_IMG_PATH)
          .concat(this.selectedNode.img_url),
        (exists) => {
          this.productImgUrl = exists
            ? this.productImgUrl
                .concat(Constants.CATALOG_IMG_PATH)
                .concat(this.selectedNode.img_url)
            : Constants.EMPTYPRODUCTIMAGE;
        }
      );
    } else {
      this.productImgUrl = Constants.EMPTYPRODUCTIMAGE;
    }
  }
  checkIfImageExists(url: string, callback) {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };

      img.onerror = () => {
        callback(false);
      };
    }
  }
}
