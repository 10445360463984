import { NgClass } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
} from "@angular/forms";

@Component({
  standalone: true,
  selector: "ui-switch",
  styleUrl: "./switch.component.css",
  imports: [NgClass, FormsModule],
  templateUrl: "./switch.component.html",
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: SwitchComponent,
    },
  ],
})
export class SwitchComponent implements ControlValueAccessor {
  _checked = true;
  _dirty = false;
  _disabled = false;
  _onTouched: () => void = () => {};
  _onChange: (val: any) => void = () => {};

  @Input() checked = this._checked;
  @Input() disabled = this._disabled;
  @Output() checkedChange = new EventEmitter<boolean>();

  _toggle() {
    this._checked = !this._checked;
    this._onChange(this._checked);
    this.checkedChange.emit(this._checked);
  }

  writeValue(value: boolean): void {
    this._checked = value;
  }

  registerOnChange(onChange: any): void {
    this._onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this._dirty = true;
    this._onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean) {
    this._disabled = isDisabled;
  }
}
