import { Component, Input, OnInit } from "@angular/core";
import { NgIf } from "@angular/common";

@Component({
    selector: "app-folder-icon",
    templateUrl: "./folder-icon.component.html",
    standalone: true,
    imports: [NgIf],
})
export class FolderIconComponent {
  @Input() type: string;
  constructor() {}
}
