import { Options, NgxSliderModule } from "@angular-slider/ngx-slider";
import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { NgEventBus } from "ng-event-bus";
import BaseModel from "../../models/devices/BaseModel";
import DeviceSettingsModel from "../../models/device-settings/DeviceSettingsModel";
import SettingsSliderModel from "../../models/device-settings/DeviceSettingsSliderModel";
import { EventType } from "../../models/constants/eventType";
import { NgClass } from "@angular/common";

@Component({
    selector: "app-setting-range",
    templateUrl: "./setting-range.component.html",
    styleUrls: ["./setting-range.component.css"],
    standalone: true,
    imports: [NgxSliderModule, NgClass],
})
export class SettingRangeComponent implements OnInit, OnChanges {
  @Input() sliderDetails: SettingsSliderModel;
  @Input() productDetails: BaseModel;
  @Input() isDisableSlider: boolean;
  @Input() minValue: string;
  @Input() maxValue: string;
  @Input() step: Number;
  @Input() inputValue: string;

  options: Options = {
    floor: 0,
    ceil: 100,
    vertical: true,
    // showTicks:true,
    step: 1,
    stepsArray: [],
    disabled: false,
    hidePointerLabels: true,
    hideLimitLabels: true,
    ticksTooltip: (v: number): string => {
      return v.toString();
    },
    translate: (value: number): string => {
      if (!Number.isNaN(value)) {
        // if (this.sliderDetails.type === Constants.CURRENT.toUpperCase())
        //   return Math.round(value).toString()
        // else
        // return value.toFixed(3).replace(/\.?0+$/, "");
        return Number(value.toFixed(3).replace(/\.?0+$/, ""))
          ? Number(value.toFixed(3).replace(/\.?0+$/, "")).toLocaleString(
              localStorage.getItem("i18nextLng")
            )
          : "";
      }
    },
  };
  component: {
    type: string; // if (this.sliderDetails.inputIndex === 0) {
    // if (this.sliderDetails.inputIndex === 0) {
    //   this.sliderDetails.inputIndex = this.sliderDetails.minValue;
    // } else if (this.sliderDetails.inputIndex === (this.sliderDetails.steps - 1)) {
    //   this.sliderDetails.inputIndex = this.sliderDetails.maxValue;
    // }
    minValue: number;
    maxValue: number;
    steps: number;
    inputIndex: number;
    isSliderValueChanged: false;
  };
  constructor(public eventBus: NgEventBus) {}
  ngOnChanges() {
    if (this.sliderDetails.type !== "HORIZONTAL_MULTIPLIER") {
      let stepsArray = [];

      if (this.sliderDetails.steps !== this.options.stepsArray.length) {
        for (var i = 0; i < this.sliderDetails.steps; i++) {
          let temp = {
            value: i,
          };
          stepsArray.push(temp);
        }
      } else {
        stepsArray = this.options.stepsArray;
      }

      this.options = Object.assign({}, this.options, {
        floor: this.minValue,
        ceil: this.maxValue,
        step: this.step,
        disabled: this.isDisableSlider,
        stepsArray: stepsArray,
      });

      // this.options.stepsArray[0].value = parseInt(this.minValue);
      // this.options.stepsArray[this.options.stepsArray.length - 1].value
      //   = parseInt(this.maxValue)
      // if (this.sliderDetails.inputIndex === 0) {
      //   this.sliderDetails.inputIndex = this.sliderDetails.minValue;
      // } else if (this.sliderDetails.inputIndex === (this.sliderDetails.steps - 1)) {
      //   this.sliderDetails.inputIndex = this.sliderDetails.maxValue;
      // }
    }
  }

  ngOnInit() {
    // if (this.sliderDetails.inputIndex === 0) {
    //   this.sliderDetails.inputIndex = this.sliderDetails.minValue;
    // } else if (this.sliderDetails.inputIndex === (this.sliderDetails.steps - 1)) {
    //   this.sliderDetails.inputIndex = this.sliderDetails.maxValue;
    // }
    let stepsArray = [];
    for (var i = 0; i < this.sliderDetails.steps; i++) {
      // let num = i;
      // if (i === 0) {
      //   num = this.sliderDetails.minValue
      // } else if (i === (this.sliderDetails.steps - 1)) {
      //   num = this.sliderDetails.maxValue
      // } else {
      //   num = i
      // }

      let temp = {
        value: i,
      };
      stepsArray.push(temp);
    }
    this.options.floor = this.sliderDetails.minValue;
    this.options.ceil = this.sliderDetails.maxValue;
    this.options.step = this.sliderDetails.steps;
    this.options.disabled = this.isDisableSlider;
    this.options.stepsArray = stepsArray;
  }

  valueChanged(e) {
    let data: DeviceSettingsModel = new DeviceSettingsModel();
    data.productDetails = this.productDetails;
    this.sliderDetails.isSliderValueChanged = true;
    data.currentTime.push(this.sliderDetails);
    this.eventBus.cast(EventType.EMIT_SLIDER_CHANGES, data);
  }

  emitSliderChangesOnMouseLeave(event) {
    if (this.sliderDetails.isSliderValueChanged) {
      this.eventBus.cast(
        EventType.EMITSLIDERCHANGESONMOUSELEAVE,
        this.sliderDetails
      );
      this.sliderDetails.isSliderValueChanged = false;
    }
  }
}
