import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-close-icon",
    templateUrl: "./close-icon.component.html",
    standalone: true,
})
export class CloseIconComponent {
  @Input() height: number = 20;
  @Input() width: number = 20;
  constructor() {}
}
