import { Component, Input, OnChanges, OnDestroy } from "@angular/core";
import { NgEventBus } from "ng-event-bus";
import { MetaData } from "ng-event-bus/lib/meta-data";
import { Subscription } from "rxjs";
import BaseModel from "../../models/devices/BaseModel";
import DeviceSettingsModel from "../../models/device-settings/DeviceSettingsModel";
import SettingsSliderModel from "../../models/device-settings/DeviceSettingsSliderModel";
import { EventType } from "../../models/constants/eventType";
import { serviceFactory } from "../../services/serviceLayer/servicefactory/serviceFactory";
import ProtectiveDevice from "../../models/devices/protectiveDevice";
import { ToasterService } from "../../services/toaster.service";
import { TooltipDirective } from "../../common/tooltip.directive";
import { FormsModule } from "@angular/forms";
import { NgClass } from "@angular/common";
import { InputComponent } from "../../../../packages/ui/src/primitives/input/input.component";

@Component({
  selector: "app-setting-input",
  templateUrl: "./setting-input.component.html",
  styleUrls: ["./setting-input.component.css"],
  standalone: true,
  imports: [NgClass, FormsModule, TooltipDirective, InputComponent],
})
export class SettingInputComponent implements OnChanges, OnDestroy {
  @Input() inputlabel: string;
  @Input() inputvalue: number;
  @Input() isDisable: boolean;
  @Input() controlType: string;
  @Input() units: string;
  @Input() sliderDetails: SettingsSliderModel;
  @Input() productDetails: BaseModel;
  isValidNumber: boolean = true;
  subscriptions: Subscription = new Subscription();

  constructor(
    private eventBus: NgEventBus,
    public toasterService: ToasterService
  ) {
    const CHANGE_SLIDER_VALUE = this.eventBus
      .on(EventType.CHANGE_SLIDER_VALUE)
      .subscribe(async (metadata: MetaData) => {
        let updatedProtectiveDevice: ProtectiveDevice = metadata.data;
        this.getSettingObject(updatedProtectiveDevice);
      });
    this.subscriptions.add(CHANGE_SLIDER_VALUE);
  }
  ngOnChanges() {
    this.inputlabel = this.inputlabel.replace(/gt/g, ">");
    this.inputvalue =
      this.inputvalue != undefined
        ? Number(this.inputvalue.toFixed(3))
        : this.inputvalue;
    this.sliderDetails.translatedInputValue = this.inputvalue.toLocaleString(
      localStorage.getItem("i18nextLng")
    );
    this.sliderDetails.translatedMinValue =
      this.sliderDetails.minValue.toLocaleString(
        localStorage.getItem("i18nextLng")
      );
    this.sliderDetails.translatedMaxValue =
      this.sliderDetails.maxValue.toLocaleString(
        localStorage.getItem("i18nextLng")
      );
  }
  convertLocaleNumber(num: string, locale: string) {
    const { format } = new Intl.NumberFormat(locale);
    const [, decimalSign] = /^0(.)1$/.exec(format(0.1));
    return +num
      .replace(new RegExp(`[^${decimalSign}\\d]`, "g"), "")
      .replace(decimalSign, ".");
  }
  onValueChange(value) {
    value = this.convertLocaleNumber(value, localStorage.getItem("i18nextLng"));
    if (value > 0) {
      if (
        value < this.sliderDetails.maxValue &&
        value > this.sliderDetails.minValue
      ) {
        this.isValidNumber = true;
      } else {
        this.isValidNumber = false;
      }
      let data: DeviceSettingsModel = new DeviceSettingsModel();
      data.productDetails = this.productDetails;
      data.currentTime.push(this.sliderDetails);
      data.currentTime[0].inputValue =
        value === "" ? this.sliderDetails.minValue : value;
      this.eventBus.cast(EventType.EMITINPUTEVENT, data);
    } else {
      this.sliderDetails.translatedInputValue =
        this.sliderDetails.inputValue.toLocaleString(
          localStorage.getItem("i18nextLng")
        );
    }
  }
  async getSettingObject(protectiveDevice: ProtectiveDevice) {
    await serviceFactory.FacadeService.getSettingsObject(
      this.productDetails,
      protectiveDevice
    )
      .then((res: Array<DeviceSettingsModel>) => {
        res.forEach((sli) => {
          if (this.sliderDetails.curveType === sli.curveType) {
            sli.currentTime.forEach((cur) => {
              if (this.sliderDetails.type === cur.type) {
                this.sliderDetails.inputValue = cur.inputValue;
                this.sliderDetails.inputIndex = cur.inputIndex;
                this.inputvalue = cur.inputValue;
                this.sliderDetails.translatedInputValue =
                  this.inputvalue.toLocaleString(
                    localStorage.getItem("i18nextLng")
                  );
                this.sliderDetails.translatedMinValue =
                  this.sliderDetails.minValue.toLocaleString(
                    localStorage.getItem("i18nextLng")
                  );
                this.sliderDetails.translatedMaxValue =
                  this.sliderDetails.maxValue.toLocaleString(
                    localStorage.getItem("i18nextLng")
                  );
                this.isValidNumber = true;
              }
            });
          }
        });
      })
      .catch((err) => {
        this.toasterService.showError(err?.message);
      });
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
