import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-properties-icon",
    templateUrl: "./properties-icon.component.html",
    standalone: true,
})
export class PropertiesIconComponent {
  constructor() {}
}
