import { Component, Input, OnInit } from "@angular/core";
import { LogoutIconComponent } from "./logout-icon/logout-icon.component";
import { SettingIconComponent } from "./setting-icon/setting-icon.component";
import { PropertiesIconComponent } from "./properties-icon/properties-icon.component";
import { EyeIconComponent } from "./eye-icon/eye-icon.component";
import { LinkIconComponent } from "./link-icon/link-icon.component";
import { GlobeIconComponent } from "./globe-icon/globe-icon.component";
import { ResetIconComponent } from "./reset-icon/reset-icon.component";
import { DocumentIconComponent } from "./document-icon/document-icon.component";
import { FolderIconComponent } from "./folder-icon/folder-icon.component";
import { ArrowIconComponent } from "./arrow-icon/arrow-icon.component";
import { SclIconComponent } from "./scl-icon/scl-icon.component";
import { MoveUpDownComponent } from "./move-up-down/move-up-down.component";
import { CloseIconComponent } from "./close-icon/close-icon.component";
import { DeleteIconComponent } from "./delete-icon/delete-icon.component";
import { DownloadIconComponent } from "./download-icon/download-icon.component";
import { EditIconComponent } from "./edit-icon/edit-icon.component";
import { UploadIconComponent } from "./upload-icon/upload-icon.component";
import { PlusIconComponent } from "./plus-icon/plus-icon.component";
import { HomeIconComponent } from "./home-icon/home-icon.component";
import { CurvesIconComponent } from "./curves-icon/curves-icon.component";
import { InfoIconComponent } from "./info-icon/info-icon.component";
import { NgSwitch, NgSwitchCase } from "@angular/common";
import { SearchIconComponent } from "./search-icon/search-icon.component";

@Component({
    selector: "app-icons",
    templateUrl: "./icons.component.html",
    styleUrls: ["./icons.component.css"],
    standalone: true,
    imports: [
        NgSwitch,
        NgSwitchCase,
        InfoIconComponent,
        CurvesIconComponent,
        HomeIconComponent,
        PlusIconComponent,
        UploadIconComponent,
        EditIconComponent,
        DownloadIconComponent,
        DeleteIconComponent,
        CloseIconComponent,
        MoveUpDownComponent,
        SclIconComponent,
        ArrowIconComponent,
        FolderIconComponent,
        DocumentIconComponent,
        ResetIconComponent,
        GlobeIconComponent,
        LinkIconComponent,
        EyeIconComponent,
        PropertiesIconComponent,
        SettingIconComponent,
        LogoutIconComponent,
        SearchIconComponent
    ],
})
export class IconsComponent {
  @Input() iconName: string;
  @Input() height: number = 20;
  @Input() width: number = 20;
  @Input() type: string;
  constructor() {}
}
