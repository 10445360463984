import { Component, Input, OnInit } from "@angular/core";
import { NgIf } from "@angular/common";

@Component({
    selector: "app-link-icon",
    templateUrl: "./link-icon.component.html",
    standalone: true,
    imports: [NgIf],
})
export class LinkIconComponent {
  @Input() type: string;
  constructor() {}
}
