import { NgClass, NgFor, NgIf } from "@angular/common";
import { Component, Inject } from "@angular/core";
import {
  MatDialogClose,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { CloseIconComponent } from "../../icons/close-icon/close-icon.component";
import { ButtonComponent } from "../../../../packages/ui/src/primitives/button/button.component";
import DialogModel from "../../models/common/DialogModel";

@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrls: ["./confirmation-dialog.component.css"],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    MatDialogClose,
    NgClass,
    TranslateModule,
    ButtonComponent,
    CloseIconComponent,
  ],
})
export class ConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModel
  ) {
    this.dialogRef.disableClose = true;
  }

  cancelEvent() {
    this.dialogRef.close(false);
  }
  discardEvent() {
    this.dialogRef.close(false);
  }
  delete() {
    this.dialogRef.close(true);
  }
  proceed() {
    this.dialogRef.close(true);
  }
  close() {
    this.dialogRef.close();
  }
  onActionClick(actionType: string) {
    if (["primary", "critical"].includes(actionType)) {
      this.dialogRef.close(true);
    } else {
      this.dialogRef.close(false);
    }
  }
}
