<div class="h-100 overflow-hidden">
  <div class="search-field">
    <div class="search-container pb-2">
      <input
        type="text"
        data-testid="catalogSearch"
        placeholder="{{ 'CatalogSearchControl_SearchLink' | translate }}"
        ui-input
        [(ngModel)]="searchedText"
        (ngModelChange)="this.searchUpdate.next($event)"
        class="w-100"
      />
      <div *ngIf="!searchedText" class="icon">
        <app-search-icon />
      </div>
      <div *ngIf="searchedText" class="icon" (click)="clearSearchTextCatalog()">
        <app-close-icon />
      </div>
    </div>
  </div>

  <div class="catalog-tree">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node
        *matTreeNodeDef="let node"
        matTreeNodePadding
        matTreeNodeToggle
        matTreeNodePaddingIndent="20"
        (click)="onNodeClick(node)"
        [ngClass]="activeNode === node ? 'childnode-highlight' : ''"
        class="cursor-pointer catalog-tree__node-width"
      >
        <app-icons [iconName]="'document'"></app-icons>
        <span
          [ngClass]="
            activeNode === node
              ? 'catalog-tree__node-highlight'
              : 'catalog-tree__node'
          "
        >
          <label>{{ node.name }}</label>
        </span>
      </mat-tree-node>
      <mat-tree-node
        *matTreeNodeDef="let node; when: hasChild"
        matTreeNodePaddingIndent="20"
        matTreeNodePadding
        matTreeNodeToggle
        (click)="onNodeClick(node)"
        [ngClass]="
          activeNode === node
            ? 'catalog-tree__node-highlight'
            : treeControl.isExpanded(node)
              ? 'node-expand-icons'
              : 'catalog-tree__node'
        "
        class="cursor-pointer catalog-tree__node-width"
      >
        <div class="d-flex">
          <app-icons
            *ngIf="treeControl.isExpanded(node)"
            [iconName]="'arrow'"
            [type]="'down'"
          ></app-icons>
          <app-icons
            *ngIf="!treeControl.isExpanded(node)"
            [iconName]="'arrow'"
            [type]="'right'"
          ></app-icons>
          <app-icons
            *ngIf="treeControl.isExpanded(node)"
            [iconName]="'folder'"
            [type]="'open'"
          ></app-icons>
          <app-icons
            *ngIf="!treeControl.isExpanded(node)"
            [iconName]="'folder'"
            [type]="'close'"
          ></app-icons>
        </div>
        <span
          [ngClass]="
            activeNode === node
              ? 'catalog-tree__node-highlight'
              : treeControl.isExpanded(node)
                ? 'node-expand'
                : 'catalog-tree__node'
          "
          class="pad-10"
        >
          {{ node.name }}
        </span>
      </mat-tree-node>
    </mat-tree>
  </div>
</div>
