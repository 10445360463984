import { NgClass, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Constants } from "./models/constants/Constants";
import { ConsoleToggleService } from "./services/ConsoleToggleService";
import { serviceFactory } from "./services/serviceLayer/servicefactory/serviceFactory";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [NgClass, NgIf, RouterOutlet],
})
export class AppComponent implements OnInit {
  constructor(
    private consoleToggleService: ConsoleToggleService,
    private translate: TranslateService
  ) {
    this.consoleToggleService.disableConsoleInProduction();
  }

  ngOnInit() {
    if (!localStorage.getItem("i18nextLng")) {
      this.setApplicationLocale();
    }
    this.translate.setDefaultLang(localStorage.getItem("i18nextLng"));
  }
  setApplicationLocale() {
    let locale = navigator.language.split("-");
    if (locale.length > 0 && locale[0]) {
      let isLocaleExist =
        serviceFactory.LocalizationService.getLocaleList().findIndex(
          (x) => x === locale[0]
        );
      isLocaleExist !== -1
        ? localStorage.setItem("i18nextLng", locale[0])
        : localStorage.setItem("i18nextLng", Constants.DEFAULT_LOCALE);
    }
  }
}
