<div
  class="h-100 w-100 p-4"
  [ngClass]="spinnerModel.show ? 'pointer-none' : ''"
>
  <div class="d-flex justify-content-between pb-2">
    <h3>
      {{ header | translate }}
    </h3>
    <button ui-button size="icon-small" variant="tertiary" (click)="close()">
      <app-close-icon />
    </button>
  </div>
  <hr />
  <div class="d-flex content">
    <div class="catalog pt-1 pe-3">
      <app-product-catalog-tree [catalogTreeData]="data.catalogTreeData">
      </app-product-catalog-tree>
    </div>
    <div class="attributes">
      <app-product-attributes
        [projectInfo]="data.projectInfo"
        (closeCatalog)="close()"
        (showSpinner)="showSpinner($event)"
      ></app-product-attributes>
    </div>
  </div>
  <app-spinner
    *ngIf="spinnerModel.show"
    [spinnerText]="spinnerModel.text"
  ></app-spinner>
</div>
