import { Component, Input, OnInit } from "@angular/core";
import { NgIf } from "@angular/common";

@Component({
    selector: "app-arrow-icon",
    templateUrl: "./arrow-icon.component.html",
    standalone: true,
    imports: [NgIf],
})
export class ArrowIconComponent {
  @Input() type: string;
  constructor() {}
}
