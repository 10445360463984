import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-upload-icon",
    templateUrl: "./upload-icon.component.html",
    standalone: true,
})
export class UploadIconComponent {
  constructor() {}
}
