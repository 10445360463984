export class LocalizationService {
  getLocaleList() {
    return [
      "bg",
      "cs",
      "da",
      "de",
      "el",
      "en",
      "es",
      "fi",
      "fr",
      "hr",
      "it",
      "lt",
      "nl",
      "pl",
      "pt",
      "ro",
      "ru",
      "sl",
      "sr",
      "tr",
      "zh",
    ];
  }
}
