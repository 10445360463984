import { CatalogAttributeValueModel } from "./CatalogAttributeValueModel";

export class CatalogAttributeModel {
  key?: string;
  values?: Array<CatalogAttributeValueModel>;
  invalidValues?: Array<CatalogAttributeValueModel>;
  takID?: string;
  unit?: string;
  requested_value?: CatalogAttributeValueModel;
}
