import { NgFor, NgIf } from "@angular/common";
import { Component, Input, OnChanges, OnDestroy } from "@angular/core";
import { NgEventBus } from "ng-event-bus";
import { MetaData } from "ng-event-bus/lib/meta-data";
import { Subscription } from "rxjs";
import { MarksService } from "../../high-chart/helpers/marks.service";
import SettingsSliderModel from "src/app/models/device-settings/DeviceSettingsSliderModel";
import ProtectiveDevice from "src/app/models/devices/protectiveDevice";
import { EventType } from "../../models/constants/eventType";
import { MarkConstants } from "../../models/constants/MarkConstants";
import DeviceSettingsModel from "../../models/device-settings/DeviceSettingsModel";
import ProductModel from "../../models/devices/ProductModel";
import MarkModel from "../../models/marks/MarkModel";
import { serviceFactory } from "../../services/serviceLayer/servicefactory/serviceFactory";
import { InputSclMarkComponent } from "../input-scl-mark/input-scl-mark.component";

@Component({
  selector: "app-settings-scl-mark",
  templateUrl: "./settings-scl-mark.component.html",
  styleUrls: ["./settings-scl-mark.component.css"],
  standalone: true,
  imports: [NgIf, NgFor, InputSclMarkComponent],
})
export class SettingsSclMarkComponent implements OnDestroy, OnChanges {
  @Input() selectedProduct: ProductModel = new ProductModel();
  @Input() markmodel: MarkModel = new MarkModel();
  @Input() settingsRange: Array<DeviceSettingsModel> =
    new Array<DeviceSettingsModel>();
  @Input() isShortCircuitLine: boolean;
  @Input() driveProjectId: string;
  xLabel: string = MarkConstants.MARK_XLABEL;
  yLabel: string = MarkConstants.MARK_YLABEL;
  tooltipXVal: string;
  tooltipYVal: string;
  subscriptions: Subscription = new Subscription();

  constructor(
    public eventBus: NgEventBus,
    private markService: MarksService
  ) {
    const CHANGE_SLIDER_VALUE = this.eventBus
      .on(EventType.CHANGE_SLIDER_VALUE)
      .subscribe(async (metadata: MetaData) => {
        let updatedProtectiveDevice: ProtectiveDevice = metadata.data;
        this.changeSettingsObject(updatedProtectiveDevice);
      });
    this.subscriptions.add(CHANGE_SLIDER_VALUE);
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  ngOnChanges() {
    this.formSCLTooltip();
    this.tooltipXVal = serviceFactory.MarksHelper.getFormattedTooltip(
      MarkConstants.MIN_IA,
      MarkConstants.MAX_IA
    );
    this.tooltipYVal = serviceFactory.MarksHelper.getTooltip(
      this.markmodel.view
    );
    this.yLabel = serviceFactory.MarksHelper.getYLabel(this.markmodel.view);
  }
  formSCLTooltip() {
    if (this.settingsRange.length > 0) {
      this.settingsRange[0].currentTime[0].translatedMinValue =
        this.settingsRange[0].currentTime[0].minValue.toLocaleString(
          localStorage.getItem("i18nextLng")
        );
      this.settingsRange[0].currentTime[0].translatedMaxValue =
        this.settingsRange[0].currentTime[0].maxValue.toLocaleString(
          localStorage.getItem("i18nextLng")
        );
    }
  }
  onSCLValueChange(value, settingValue: SettingsSliderModel) {
    let data: DeviceSettingsModel = new DeviceSettingsModel();
    data.productDetails = this.selectedProduct.product;
    data.currentTime.push(settingValue);
    data.currentTime[0].inputValue =
      value === "" ? settingValue.minValue : value;
    this.eventBus.cast(EventType.EMITINPUTEVENT, data);
  }
  onMarkXChange(updatedValue) {
    this.markmodel.current = updatedValue;
    this.onMarkValueChange(this.markmodel);
  }
  onMarkYChange(updatedValue) {
    this.markmodel.time = updatedValue;
    this.onMarkValueChange(this.markmodel);
  }
  async onMarkValueChange(updatedMarkModel: MarkModel) {
    this.markService.updateMark(updatedMarkModel, this.driveProjectId);
  }

  async changeSettingsObject(updatedProtectiveDevice: ProtectiveDevice) {
    await serviceFactory.FacadeService.getSettingsObject(
      this.selectedProduct.product,
      updatedProtectiveDevice
    ).then((res: Array<DeviceSettingsModel>) => {
      this.settingsRange = res;
      if (this.settingsRange[0].curveType === "SHORT_CIRCUIT") {
        this.formSCLTooltip();
      }
    });
  }
}
