import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import { CustomLoader } from "../language/custom.loader";
import { LanguageMissingTranslationHandler } from "../language/language.missing-translation-handler";
import { CommonHelperModule } from "../common/common-helper.module";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { DevicePropertiesComponent } from "./device-properties/device-properties.component";
import { DeviceSettingsBarComponent } from "./device-settings-bar/device-settings-bar.component";
import { SettingCheckComponent } from "./setting-check/setting-check.component";
import { SettingDropdownComponent } from "./setting-dropdown/setting-dropdown.component";
import { SettingHorizontalSliderComponent } from "./setting-horizontal-slider/setting-horizontal-slider.component";
import { SettingInputComponent } from "./setting-input/setting-input.component";
import { SettingRadioComponent } from "./setting-radio/setting-radio.component";
import { SettingRangeComponent } from "./setting-range/setting-range.component";
import { InputSclMarkComponent } from "./input-scl-mark/input-scl-mark.component";
import { SettingsSclMarkComponent } from "./settings-scl-mark/settings-scl-mark.component";
import { SettingSclMarkHeaderComponent } from "./setting-scl-mark-header/setting-scl-mark-header.component";
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: LanguageMissingTranslationHandler,
      },
    }),
    CommonHelperModule,
    NgxSliderModule,
    DevicePropertiesComponent,
    DeviceSettingsBarComponent,
    SettingCheckComponent,
    SettingDropdownComponent,
    SettingHorizontalSliderComponent,
    SettingInputComponent,
    SettingRadioComponent,
    SettingRangeComponent,
    InputSclMarkComponent,
    SettingsSclMarkComponent,
    SettingSclMarkHeaderComponent,
  ],
  exports: [
    DevicePropertiesComponent,
    DeviceSettingsBarComponent,
    SettingCheckComponent,
    SettingDropdownComponent,
    SettingHorizontalSliderComponent,
    SettingInputComponent,
    SettingRadioComponent,
    SettingRangeComponent,
    InputSclMarkComponent,
    SettingsSclMarkComponent,
    SettingSclMarkHeaderComponent,
  ],
  providers: [],
  bootstrap: [],
})
export class DeviceSettingsModule {}
