<div class="h-100 pb-3 folder-header">
  <div class="folder-name d-flex align-items-center gap-2">
    <app-home-icon />
    <h2>{{ "project-definition.myprojects" | translate }}</h2>
  </div>

  <div class="sub-header gap-3" *ngIf="isShowSubHeader">
    <div class="project-search">
      <input
        ui-input
        type="text"
        data-testid="searchProject"
        placeholder="{{ 'CatalogSearchControl_SearchLink' | translate }}"
        [(ngModel)]="searchText"
        (keyup)="onSearchProject($event)"
      />
      <div *ngIf="!searchText" class="icon">
        <app-search-icon />
      </div>
      <div *ngIf="searchText" class="icon" (click)="clearSearchText()">
        <app-close-icon />
      </div>
    </div>

    <div class="d-flex gap-3">
      <button ui-button variant="secondary" (click)="onUploadProject()">
        <app-upload-icon style="margin-right: 4px" />
        {{ "project-definition.uploadProject" | translate }}
      </button>
      <button ui-button (click)="OnCreateProject()">
        <app-plus-icon style="margin-right: 4px" />
        {{ "project-definition.createProject" | translate }}
      </button>
    </div>
  </div>
</div>
