<div *ngIf="selectedProduct && selectedProduct.productAttributes" class="p-3">
  <div
    class="d-flex flex-column"
    *ngFor="let attr of selectedProduct.productAttributes.attributes"
  >
    <strong *ngIf="attr.m_strName">
      {{ attr.m_strName }}
    </strong>
    <p class="prop-value">
      <span *ngIf="attr.m_Value">{{ attr.m_Value | translateNumber }}</span>
      <span *ngIf="attr.m_strUnit"> {{ attr.m_strUnit }}</span>
    </p>
  </div>
</div>
