import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-curves-icon",
    templateUrl: "./curves-icon.component.html",
    standalone: true,
})
export class CurvesIconComponent {
  constructor() {}
}
