<svg
  width="20"
  height="20"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g id="plus-large">
    <path
      id="Icon"
      d="M13 11V3H11V11H3V13H11V21H13V13H21V11H13Z"
      fill="currentColor"
    />
  </g>
</svg>
