import Registration from "../../models/login/Registration";
import { environment } from "../../../environments/environment";
import Profile from "../../models/login/Profile";
import AxiosClient from "../client/AxiosClient";
import { ResultType } from "../models/Result";
import serviceUrlConstants from "./serviceUrlConstants";
import { LoginConstants } from "../../models/constants/loginConstants";
import { AuthZTypes } from "../../models/login/AuthZTypes";

export default class AuthService {
  async verifyNewUser(mailID: string): Promise<boolean> {
    let url = serviceUrlConstants.getVerifyUrl(mailID);

    const response = await AxiosClient.postWithJsonResult(
      url,
      this.getVerifyRequestBody(mailID),
      {
        "Content-Type": "application/json",
      }
    );
    switch (response.type) {
      case ResultType.SUCCESS:
        return false;
      case ResultType.FAIL: {
        // Returns 404 if the email of the user is not found
        if (response.statusCode === 404) {
          return true;
        } else {
          throw response.message;
        }
      }
    }
  }

  async registerInternalNewUser(profile: Profile): Promise<Registration> {
    let registration: Registration;
    let url = serviceUrlConstants.getRegistrationIntUserUrl();
    await AxiosClient.post(
      url,
      {
        "Content-Type": "application/json",
      },
      this.getRegistrationRequestBody(profile)
    )
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS:
            registration = response.value["registration"];

            return registration;
          case ResultType.FAIL: {
            throw response.message;
          }
        }
      })
      .catch((error) => {
        throw error;
      });
    return registration;
  }
  async registerExternalNewUser(profile: Profile): Promise<Registration> {
    let registration: Registration;
    let url = serviceUrlConstants.getRegistrationExtUserUrl();
    await AxiosClient.postWithJsonResult(
      url,
      this.getRegistrationRequestBody(profile),
      {
        "Content-Type": "application/json",
      }
    )
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS:
            registration = response.value["registration"];

            return registration;
          case ResultType.FAIL: {
            throw response.message;
          }
        }
      })
      .catch((error) => {
        throw error;
      });
    return registration;
  }
  getVerifyRequestBody(mailID: string) {
    let requestBody = {
      application: environment.auth.applicationId,
      email: mailID,
    };
    return requestBody;
  }
  getRegistrationRequestBody(profile: Profile) {
    let requestBody;

    requestBody = {
      registration: {
        application: environment.auth.applicationId,
        profile: profile,
        regions: [environment.region],
        roles: [environment.role],
      },
    };
    return requestBody;
  }
  getRegistrationVerifyRequestBody(gID: string) {
    let requestBody;
    requestBody = {
      registration: {
        application: LoginConstants.APPLICATION_NAME,
        gID: gID,
      },
    };
    return requestBody;
  }
  async checkUserRegistration(gID: string): Promise<AuthZTypes> {
    let registrationStatus: AuthZTypes;
    let url = serviceUrlConstants.getRegistrationVerifyUrl();
    await AxiosClient.post(
      url,
      {
        "Content-Type": "application/json",
      },
      this.getRegistrationVerifyRequestBody(gID)
    ).then((response) => {
      switch (response.type) {
        case ResultType.SUCCESS:
          registrationStatus =
            response.value["registration"]?.registrationStatus;
          return registrationStatus;
        // if (registration.registrationStatus === AuthZTypes.NOT_REGISTERED) {
        //   isUserRegistered = false;
        // } else {
        //   isUserRegistered = true;
        // }
        case ResultType.FAIL: {
          throw response;
        }
      }
    });
    return registrationStatus;
  }
}
