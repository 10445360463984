import Product from "./product";

export default class ListEntry {
  mProduct: Product;
  mProtectiveDevice: Object;
  deps: Object;
  curveStyle: Object;
  visible: boolean;
  color: string;
  lineStyle: string;
  lineWidth: number;
  mLabel: string;
  mProductGroupId: string;
  mlfb: string;
  maxVoltage: number;
  minVoltage: number;
  voltage: string;
  ratedVoltage: number;
  ambientTemparature: number;
  index: number;
  itemType: string;
  id?: string;
}
