@if (spinnerModel.show) {
  <app-spinner [spinnerText]="spinnerModel.text"></app-spinner>
}
<div class="space-y-3 px-4 py-3 md:px-8 md:py-6">
  <!-- dialog header -->
  <div class="flex justify-between items-center">
    <h3>{{ "project-definition.uploadProject" | translate }}</h3>
    <button
      ui-button
      size="icon-small"
      variant="tertiary"
      (click)="dialogRef.close()"
    >
      <app-close-icon />
    </button>
  </div>
  <!-- dialog body -->
  <div
    class="h-52 flex flex-col justify-center items-center p-4 border-dashed border-1 border-teal-700 bg-teal-100 rounded gap-2"
    (drop)="handleDrop($event)"
    (dragover)="handleDragOver($event)"
  >
    <p class="text-center font-semibold">
      {{ "project-definition.upload_dragDrop" | translate }}
    </p>
    <p class="text-center">{{ "project-definition.upload_or" | translate }}</p>
    <button ui-button data-testid="file-input" class="relative">
      <label class="text-white font-semibold text-sm absolute inset-0">
        <input
          type="file"
          accept=".scu"
          class="hidden"
          (change)="getfile($event)" /></label
      >{{ "project-definition.upload_browseFile" | translate }}
    </button>
    @if (selectedFileName) {
      <p>{{ selectedFileName }}</p>
    }
  </div>
  <div class="format mt-1">
    <p>{{ "project-definition.upload_format" | translate }}: .scu</p>
  </div>
  <!-- dialog footer -->
  <div class="flex justify-between md:justify-end gap-3">
    <button ui-button variant="secondary" (click)="dialogRef.close()">
      {{ "CANCEL" | translate }}
    </button>
    <button
      ui-button
      (click)="onUpload()"
      data-testid="uploadFileButton"
      [disabled]="!invalidFile || isFileEmpty || spinnerModel.show"
    >
      {{ "project-definition.upload" | translate }}
    </button>
  </div>
</div>
