import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-home-icon",
    templateUrl: "./home-icon.component.html",
    styles: [":host { display: grid; place-content: center }"],
    standalone: true,
})
export class HomeIconComponent {
  constructor() {}
}
