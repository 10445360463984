<div
  class="p-0 pad horizontal d-flex justify-content-between align-items-center"
  [ngClass]="isDisable ? 'disabled' : ''"
  (mouseleave)="emitSliderChangesOnMouseLeave($event)"
>
  <div style="width: 50%; margin-top: 20px">
    <ngx-slider
      [hidden]="sliderDetails.type !== 'HORIZONTAL_MULTIPLIER'"
      [ngClass]="isDisable ? ' horizontal__slider-disabled ' : ''"
      [(value)]="sliderDetails.inputValue"
      [options]="horizontal_options"
      (userChange)="onValueChanged($event)"
    ></ngx-slider>
  </div>

  <div class="isd_ref">
    <label
      class="d-flex justify-content-end pe-"
      [ngClass]="isDisable ? 'horizontal-label--disabled' : 'horizontal-label'"
      >{{ sliderDetails.inputLabel }}
    </label>
    <input
      [ngClass]="isDisable ? 'horizontal__input-disabled' : ''"
      type="number"
      class="form-control form-control-sm horizontal__input"
      [disabled]="isDisable"
      value="{{ sliderDetails.inputValue }}"
      (change)="onInputValueChanged($event.target.value)"
    />
  </div>
  <div>
    <label style="padding-left: 0.5rem"> x Ir </label>
  </div>
</div>
