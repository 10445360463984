import { Component, Input } from "@angular/core";
import { NgEventBus } from "ng-event-bus";
import BaseModel from "../../models/devices/BaseModel";
import { EventType } from "../../models/constants/eventType";
import DeviceSettingsModel from "../../models/device-settings/DeviceSettingsModel";
import { SwitchComponent } from "../../../../packages/ui/src/primitives/switch/switch.component";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "app-setting-check",
  templateUrl: "./setting-check.component.html",
  standalone: true,
  imports: [FormsModule, SwitchComponent],
})
export class SettingCheckComponent {
  @Input() label: string;
  @Input() curveType: string;
  @Input() selectedProductIndex: number;
  @Input() productDetails: BaseModel;
  @Input() isEngaged: boolean;

  constructor(public eventBus: NgEventBus) {}

  onChecked(isChecked: boolean) {
    let setting: DeviceSettingsModel = {
      ...new DeviceSettingsModel(),
      curveType: this.curveType,
      isEngaged: isChecked,
      productDetails: this.productDetails,
    };

    this.eventBus.cast(EventType.EMIT_CHECKED_EVENT, setting);
  }
}
