import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-plus-icon",
  templateUrl: "./plus-icon.component.html",
  standalone: true,
})
export class PlusIconComponent {
  constructor() {}
}
