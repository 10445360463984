import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AxiosError } from "axios";
import { MetaData, NgEventBus } from "ng-event-bus";
import { DeviceCatalogDialogComponent } from "../../device-catalog-component/device-catalog-dialog/device-catalog-dialog.component";
import CatalogDialogModel from "../../models/catalog/CatalogDialogModel";
import { ProductNode } from "../../models/catalog/CatalogProductNodeModel";
import { Constants } from "../../models/constants/Constants";
import ProjectInfo from "../../models/drive/projectInfo";
import { EventType } from "../../models/constants/eventType";
import MarkModel from "../../models/marks/MarkModel";
import ProductModel from "../../models/devices/ProductModel";
import ListEntry from "../../models/devices/ListEntry";
import { serviceFactory } from "../../services/serviceLayer/servicefactory/serviceFactory";
import { SessionExpiredService } from "../../services/serviceLayer/session-expired-dialog.service";
import { ToasterService } from "../../services/toaster.service";
import { Subscription } from "rxjs";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule } from "@angular/forms";
import { NgIf } from "@angular/common";
import { TooltipDirective } from "../../common/tooltip.directive";
import { InputComponent } from "./../../../../packages/ui/src/primitives/input/input.component";
import { ButtonComponent } from "../../../../packages/ui/src/primitives/button/button.component";
import { ArrowIconComponent } from "../../icons/arrow-icon/arrow-icon.component";
import { EyeIconComponent } from "../../icons/eye-icon/eye-icon.component";
import { DeleteIconComponent } from "../../icons/delete-icon/delete-icon.component";
import { EditIconComponent } from "../../icons/edit-icon/edit-icon.component";
import { MarksService } from "../../high-chart/helpers/marks.service";

@Component({
  selector: "app-setting-scl-mark-header",
  templateUrl: "./setting-scl-mark-header.component.html",
  styleUrls: ["./setting-scl-mark-header.component.css"],
  standalone: true,
  imports: [
    TooltipDirective,
    NgIf,
    FormsModule,
    TranslateModule,
    InputComponent,
    ButtonComponent,
    ArrowIconComponent,
    EyeIconComponent,
    DeleteIconComponent,
    EditIconComponent,
  ],
})
export class SettingSclMarkHeaderComponent implements OnChanges, OnDestroy {
  @Input() selectedProduct: ProductModel = new ProductModel();
  @Input() projectinfo: ProjectInfo;
  @Input() selectedMark: MarkModel = new MarkModel();
  @Input() catalogTreeData: ProductNode;
  @Input() isMarkSelected: boolean;
  @Input() selectedViewMarksList: MarkModel[];
  settingsHeader: string = "tooltip.devices";
  productNameLabel: string;
  markNameLabel: string;
  subscriptions: Subscription = new Subscription();
  @ViewChild("deviceName") productName: ElementRef;
  @ViewChild("markName") markName: ElementRef;
  constructor(
    public eventBus: NgEventBus,
    private toasterService: ToasterService,
    private sessionExpiredDialog: SessionExpiredService,
    public dialog: MatDialog,
    private markService: MarksService
  ) {
    const EMIT_SELECTED_MARK = this.eventBus
      .on(EventType.EMITSELECTEDMARK)
      .subscribe((metaData: MetaData) => {
        this.selectedMark = metaData.data;
        this.selectedMark ? (this.markNameLabel = this.selectedMark.label) : "";
      });
    const onEditProduct = this.eventBus
      .on(EventType.CHARTDATATOBEUPDATED)
      .subscribe(async (metadata: MetaData) => {
        this.selectedProduct = metadata.data;
        this.selectedProduct && this.selectedProduct.product
          ? (this.productNameLabel = this.selectedProduct.product.name)
          : "";
      });
    const onEditScl = this.eventBus
      .on(EventType.SCL_INPUT_LABEL)
      .subscribe(async (metadata: MetaData) => {
        this.selectedProduct = metadata.data;
        this.selectedProduct && this.selectedProduct.product
          ? (this.productNameLabel = this.selectedProduct.product.name)
          : "";
      });

    this.subscriptions.add(onEditProduct);
    this.subscriptions.add(EMIT_SELECTED_MARK);
    this.subscriptions.add(onEditScl);
  }
  ngOnChanges() {
    this.setHeader();
    this.selectedProduct && this.selectedProduct.product
      ? (this.productNameLabel = this.selectedProduct.product.name)
      : "";
    this.selectedMark ? (this.markNameLabel = this.selectedMark.label) : "";
  }
  setHeader() {
    if (this.isMarkSelected) {
      this.settingsHeader = "Action_addMark";
    } else {
      if (
        this.selectedProduct &&
        this.selectedProduct.product &&
        this.selectedProduct.product.type === Constants.SHORTCIRCUITLINE
      ) {
        this.settingsHeader = "Action_add_shortcurcuitcurrent";
      } else if (
        this.selectedProduct &&
        this.selectedProduct.product &&
        this.selectedProduct.product.type !== Constants.SHORTCIRCUITLINE
      ) {
        this.settingsHeader = "tooltip.devices";
      }
    }
  }
  collapseSideBar() {
    this.eventBus.cast(EventType.OPENADDDEVICESIDEBAR, false);
    setTimeout(() => {
      this.eventBus.cast(EventType.AFTERSIDEBARNAVIGATION, true);
    }, 300);
  }
  showHideDevice() {
    if (!this.isMarkSelected) {
      this.selectedProduct.product.toggleSlashEye =
        !this.selectedProduct.product.toggleSlashEye;
      this.eventBus.cast(EventType.SHOW_HIDE_GRAPH, this.selectedProduct);
    } else {
      this.showHideMark();
    }
  }
  async showHideMark() {
    this.selectedMark.visible = !this.selectedMark.visible;
    this.markService.updateMark(this.selectedMark, this.projectinfo.id);
  }
  deleteDevice() {
    if (!this.isMarkSelected) {
      this.eventBus.cast(
        EventType.EMIT_DELETE_EVENT,
        this.selectedProduct.product
      );
    } else {
      this.eventBus.cast(EventType.EMIT_DELETEMARK_EVENT, this.selectedMark);
    }
  }
  onEditProductDetails() {
    let catalogDialogModel = new CatalogDialogModel();
    catalogDialogModel.catalogTreeData = this.catalogTreeData;
    catalogDialogModel.projectInfo = this.projectinfo;
    catalogDialogModel.isEditDevice = true;
    const dialogRef = this.dialog.open(DeviceCatalogDialogComponent, {
      width: "80%",
      height: "83%",
      data: catalogDialogModel,
    });
    setTimeout(() => {
      this.eventBus.cast(EventType.ONEDITPRODUCTDETAILS, this.selectedProduct);
    }, 500);
  }
  async editDeviceLabel() {
    if (this.productNameLabel !== this.selectedProduct.product.name) {
      await serviceFactory.ProductService.updateDeviceLabel(
        this.selectedProduct.facadeListEntry.listEntry,
        this.productNameLabel,
        this.selectedProduct.facadeListEntry.listEntry.index,
        this.projectinfo.id
      )
        .then((response: ListEntry) => {
          this.selectedProduct.product.name = response.mLabel;
          this.selectedProduct.facadeListEntry.listEntry.mLabel =
            response.mLabel;
          this.productNameLabel = response.mLabel;
          this.eventBus.cast(EventType.ONDEVICELABELEDIT, this.selectedProduct);
        })
        .catch((err: AxiosError) => {
          this.productNameLabel = this.selectedProduct.product.name;
          if (err.response.data === Constants.SESSIONEXPIRED) {
            this.sessionExpiredDialog.openDialog();
          } else {
            this.toasterService.showError(err?.message);
          }
        });
    }
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  async editmarkLabel() {
    let updatedMarkIndex: number = this.selectedMark.index;
    if (this.markNameLabel !== this.selectedMark.label) {
      let updateValue = new MarkModel();
      updateValue = this.selectedMark;
      updateValue.label = this.markNameLabel;
      this.markService.updateMark(updateValue, this.projectinfo.id);
    }
  }
}
