import Highcharts from "highcharts";
import { Constants } from "../../models/constants/Constants";
import { toSuperScript } from "../../../lib/super-script";
import { ChartType } from "../../models/constants/chartType";
import { MarkViewType } from "../../models/constants/mark-view-type";
import { MarkConstants } from "../../models/constants/MarkConstants";
import ProductModel from "../../models/devices/ProductModel";
import MarkModel from "../../models/marks/MarkModel";
import { serviceFactory } from "../../services/serviceLayer/servicefactory/serviceFactory";

export default class MarksHelper {
  formatXYValue(chartData, offsetX: number, offsetY: number) {
    let xValue, yValue;
    xValue = parseFloat(
      parseFloat(chartData.ref.xAxis[0].toValue(offsetX)).toFixed(3)
    );
    yValue = parseFloat(
      parseFloat(chartData.ref.yAxis[0].toValue(offsetY)).toFixed(3)
    );
    return [xValue, yValue];
  }
  createMarkModel(
    x: number,
    y: number,
    chartType: ChartType,
    isMarkOnSeriesData: boolean,
    ownerIndex?: number
  ) {
    let markModel = new MarkModel();
    markModel.current = x;
    markModel.time = y;
    switch (chartType) {
      case ChartType.TRIP:
        markModel.view = MarkViewType.TRIP;
        break;
      case ChartType.CURRENT:
        markModel.view = MarkViewType.CURRENT;
        break;
      case ChartType.LETTHROUGH:
        markModel.view = MarkViewType.ENERGY;
        break;
    }
    markModel.isMarkOnSeriesData = isMarkOnSeriesData;
    markModel.ownerIndex = ownerIndex;
    return markModel;
  }
  withinRange(current: number, time: number, chartType: ChartType) {
    switch (chartType) {
      case ChartType.TRIP:
        return (
          time >= MarkConstants.MIN_Ts &&
          time <= MarkConstants.MAX_Ts &&
          this.isCurrentValueValid(current)
        );
      case ChartType.CURRENT:
        return (
          time >= MarkConstants.MIN_IC &&
          time <= MarkConstants.MAX_IC &&
          this.isCurrentValueValid(current)
        );
      case ChartType.LETTHROUGH:
        return (
          time >= MarkConstants.MIN_I2t &&
          time <= MarkConstants.MAX_I2t &&
          this.isCurrentValueValid(current)
        );
    }
  }
  isCurrentValueValid(current: number) {
    return current >= MarkConstants.MIN_IA && current <= MarkConstants.MAX_IA;
  }
  getOwnerIndex(chartType: ChartType, selectedProduct: ProductModel) {
    let ownerIndex;
    switch (chartType) {
      case ChartType.TRIP:
        ownerIndex = -1; //Owner index is not required in case of TRIP view hence random number
        break;
      case ChartType.CURRENT:
      case ChartType.LETTHROUGH:
        ownerIndex = selectedProduct?.facadeListEntry?.listEntry?.index;
    }
    return ownerIndex;
  }
  formatMarksLabel(item: MarkModel) {
    let label;
    let convertedValueX;
    let translatedCurrentValue =
      item.current.toLocaleString(localStorage.getItem("i18nextLng")) + " A";
    let translatedTimeValue = item.time.toLocaleString(
      localStorage.getItem("i18nextLng")
    );
    if (item.current >= 1000) {
      convertedValueX = (item.current / 1000).toFixed(3);
      translatedCurrentValue =
        convertedValueX.toLocaleString(localStorage.getItem("i18nextLng")) +
        "kA";
    }
    switch (item.view) {
      case MarkViewType.ENERGY:
        {
          label =
            "I = " +
            translatedCurrentValue +
            "  / I" +
            toSuperScript("2") +
            "t = " +
            translatedTimeValue +
            "kA" +
            toSuperScript("2") +
            "s";
        }
        break;
      case MarkViewType.CURRENT:
        {
          label =
            "I = " +
            translatedCurrentValue +
            "/ Ic = " +
            translatedTimeValue +
            "kA";
        }
        break;

      case MarkViewType.TRIP:
        label =
          "I = " +
          translatedCurrentValue +
          " / t = " +
          translatedTimeValue +
          "s";
        break;
    }
    return label;
  }
  getFormattedTooltip(minValue: Number, maxValue: Number) {
    let translatedMinValue, translatedMaxValue;
    if (minValue !== undefined && minValue !== null)
      translatedMinValue = minValue.toLocaleString(
        localStorage.getItem("i18nextLng")
      );
    if (maxValue !== undefined && maxValue !== null)
      translatedMaxValue = maxValue.toLocaleString(
        localStorage.getItem("i18nextLng")
      );
    return translatedMinValue + "..." + translatedMaxValue;
  }
  getTooltip(markView: MarkViewType) {
    switch (markView) {
      case MarkViewType.TRIP: {
        return serviceFactory.MarksHelper.getFormattedTooltip(
          MarkConstants.MIN_Ts,
          MarkConstants.MAX_Ts
        );
      }
      case MarkViewType.CURRENT: {
        return serviceFactory.MarksHelper.getFormattedTooltip(
          MarkConstants.MIN_IC,
          MarkConstants.MAX_IC
        );
      }
      case MarkViewType.ENERGY: {
        return serviceFactory.MarksHelper.getFormattedTooltip(
          MarkConstants.MIN_I2t,
          MarkConstants.MAX_I2t
        );
      }
    }
  }
  getYLabel(markView: MarkViewType) {
    switch (markView) {
      case MarkViewType.TRIP:
        return MarkConstants.MARK_YLABEL;
      case MarkViewType.CURRENT:
        return MarkConstants.MARK_YLABEL_IC;
      case MarkViewType.ENERGY:
        return "I" + toSuperScript("2") + "t[A" + toSuperScript("2") + "s]";
    }
  }
  isMarkTimeValid(time: number, view: MarkViewType) {
    switch (view) {
      case MarkViewType.TRIP:
        return time >= MarkConstants.MIN_Ts && time <= MarkConstants.MAX_Ts;
      case MarkViewType.CURRENT:
        return time >= MarkConstants.MIN_IC && time <= MarkConstants.MAX_IC;
      case MarkViewType.ENERGY:
        return time >= MarkConstants.MIN_I2t && time <= MarkConstants.MAX_I2t;
    }
  }
  checkChartType(view: MarkViewType, chartType: ChartType) {
    if (chartType === ChartType.TRIP && view === MarkViewType.TRIP) {
      return true;
    } else if (
      chartType === ChartType.LETTHROUGH &&
      view === MarkViewType.ENERGY
    ) {
      return true;
    } else if (
      chartType === ChartType.CURRENT &&
      view === MarkViewType.CURRENT
    ) {
      return true;
    } else {
      return false;
    }
  }
  checkIfMarkExist(chartData: any, markIndex: string) {
    let isExist = false;
    if (
      chartData &&
      chartData.ref &&
      chartData.ref.series &&
      chartData.ref.series.length > 0
    ) {
      isExist = chartData.ref.series.find((x) => x.options.id === markIndex);
    }
    return isExist;
  }
  addMarkOnChart(item: MarkModel, chartData: any, chartType: ChartType) {
    if (
      this.checkChartType(item.view, chartType) &&
      !this.checkIfMarkExist(chartData, item.id)
    ) {
      Highcharts.SVGRenderer.prototype.symbols.cross = function (x, y, w, h) {
        return [
          "M",
          x,
          y,
          "L",
          x + w,
          y + h,
          "M",
          x + w,
          y,
          "L",
          x,
          y + h,
          "z",
        ];
      };
      const lineWidth = item.isChecked
        ? Constants.HIGHLIGHT_MARKWIDTH
        : Constants.DEFAULT_MARKWIDTH;
      chartData.addSeries({
        id: item.id,
        name: item.label,
        data: [{ x: item.current, y: item.time }],
        color: item.rgbValue,
        showInLegend: false,
        visible: item.visible,
        marker: {
          symbol: "cross",
          enabled: true,
          lineColor: item.rgbValue,
          lineWidth: lineWidth,
          radius: 6,
        },
        dataLabels: {
          color: item.rgbValue,
          enabled: true,
          allowOverlap: true,
          y: 30,
          format: item.translatedLabel,
          style: {
            textOutline: 0,
            textShadow: false,
          },
          borderWidth: 0,
        },
      });
      if (chartType !== ChartType.TRIP) {
        this.setYAxisExtremes(
          Math.floor(Math.log10(chartData.ref.yAxis[0].dataMin)),
          Math.ceil(Math.log10(chartData.ref.yAxis[0].dataMax)),
          chartData
        );
      }
    }
  }
  getViewBasedOnTab(tabNumber: number) {
    switch (tabNumber) {
      case 0:
        return MarkViewType.TRIP;
      case 1:
        return MarkViewType.CURRENT;
      case 2:
        return MarkViewType.ENERGY;
    }
  }
  getChartTypeBasedOnTab(tabNumber: number) {
    switch (tabNumber) {
      case 0:
        return ChartType.TRIP;
      case 1:
        return ChartType.CURRENT;
      case 2:
        return ChartType.LETTHROUGH;
    }
  }
  setYAxisExtremes(dataMin: number, dataMax: number, chartData) {
    dataMin = dataMin - 1;
    dataMax = dataMax + 1;
    chartData.ref.yAxis[0].setExtremes(
      Math.pow(10, dataMin),
      Math.pow(10, dataMax)
    );
  }
}
