<div class="settings">
  <div
    *ngIf="
      settingsRange.length > 0 && selectedProduct.type !== 'shortcircuitline'
    "
  >
    <div *ngIf="relaySetting !== undefined" class="settings__relay">
      <app-setting-dropdown
        *ngFor="let setting of relaySetting"
        [relaySetting]="setting"
      >
      </app-setting-dropdown>
    </div>
    <div class="settings__content">
      <mat-accordion class="device-config" *ngFor="let prod of settingsRange">
        <mat-expansion-panel class="expansion-panel" [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="settings__part-type">
                <h4>
                  {{ prod.curveType | translate }}
                </h4>
                <div>
                  <app-setting-check
                    (click)="$event.stopPropagation()"
                    [hidden]="!prod.isDisengageable"
                    [label]="'On'"
                    [curveType]="prod.curveType"
                    [isEngaged]="prod.isEngaged"
                    [productDetails]="prod.productDetails"
                  ></app-setting-check>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description
            ><div class="row pad-r-0 w-100">
              <div class="pad-b w-100">
                <app-setting-radio
                  *ngIf="prod.radioActions.length === 2"
                  [deviceSetting]="prod"
                ></app-setting-radio>
                <app-setting-dropdown
                  *ngIf="prod.radioActions.length > 2"
                  [characteristicList]="prod.radioActions"
                  [activeCharacteristic]="prod.activeCharacteristic"
                  [curveType]="prod.curveType"
                  [productDetails]="prod.productDetails"
                  [isDisable]="!prod.isEngaged"
                >
                </app-setting-dropdown>
              </div>
              <div class="pad-r-0 w-100 setting-input">
                <div
                  *ngFor="let settingValue of prod.currentTime"
                  class="col-md-6"
                >
                  <app-setting-input
                    *ngIf="
                      settingValue.inputValue !== null &&
                      settingValue.type !== 'HORIZONTAL_MULTIPLIER'
                    "
                    [inputlabel]="settingValue.inputLabel"
                    [units]="settingValue.units"
                    [inputvalue]="settingValue.inputValue"
                    [isDisable]="!prod.isEngaged"
                    [controlType]="settingValue.type"
                    [sliderDetails]="settingValue"
                    [productDetails]="prod.productDetails"
                  >
                  </app-setting-input>
                  <div
                    class="d-flex pt-2 pe-3"
                    *ngIf="
                      settingValue.inputValue !== null &&
                      settingValue.minValue !== settingValue.maxValue &&
                      prod.curveType !== 'SHORT_CIRCUIT'
                    "
                  >
                    <app-setting-range
                      class="col-md-6 settings__slider"
                      [hidden]="settingValue.type === 'HORIZONTAL_MULTIPLIER'"
                      [sliderDetails]="settingValue"
                      [productDetails]="prod.productDetails"
                      [minValue]="settingValue.minValue"
                      [maxValue]="settingValue.maxValue"
                      [step]="settingValue.steps"
                      [isDisableSlider]="!prod.isEngaged"
                      [inputValue]="settingValue.inputValue"
                    >
                    </app-setting-range>

                    <div
                      class="w-50 d-flex justify-content-center align-items-center"
                      *ngIf="settingValue.type !== 'HORIZONTAL_MULTIPLIER'"
                    >
                      <button
                        ui-button
                        size="small"
                        variant="{{
                          settingValue.controlType === 'ESET'
                            ? 'primary'
                            : 'secondary'
                        }}"
                        *ngIf="
                          settingValue.controlTypeList !== undefined &&
                          settingValue.controlTypeList !== null &&
                          settingValue.controlTypeList.includes('ESET')
                        "
                        [disabled]="!prod.isEngaged"
                        (click)="onESETClick(settingValue)"
                      >
                        e.SET
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row pad-r-0" *ngIf="prod.currentRef">
                <app-setting-horizontal-slider
                  [hidden]="prod.currentRef.type !== 'HORIZONTAL_MULTIPLIER'"
                  [sliderDetails]="prod.currentRef"
                  [productDetails]="prod.productDetails"
                  [isDisable]="!prod.isEngaged"
                >
                </app-setting-horizontal-slider>
              </div>
            </div>
          </mat-panel-description>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div
    *ngIf="
      settingsRange.length > 0 && selectedProduct.type === 'shortcircuitline'
    "
  >
    <app-settings-scl-mark
      [selectedProduct]="selectedProductData"
      [settingsRange]="settingsRange"
      [isShortCircuitLine]="true"
      [driveProjectId]="driveProjectId"
    >
    </app-settings-scl-mark>
  </div>
  <div *ngIf="markmodel && isMarkSelected">
    <app-settings-scl-mark
      [markmodel]="markmodel"
      [isShortCircuitLine]="false"
      [driveProjectId]="driveProjectId"
    >
    </app-settings-scl-mark>
  </div>
</div>
