import { Routes } from "@angular/router";
import { IsAuthorized, IsAuthenticatedGuard } from "./guards/auth.guard";
import { SaveProjectGuard } from "./guards/save-project.guard";
import { LoginGuard } from "./guards/login.guard";

export const routes: Routes = [
  {
    path: "login",
    // FIXME: Every page should have a title: https://angular.dev/guide/routing/common-router-tasks#setting-the-page-title
    canActivate: [LoginGuard],
    loadComponent: () =>
      import("./login-page/login-page.component").then(
        (comp) => comp.LoginPageComponent
      ),
  },
  {
    path: "",
    canActivate: [IsAuthorized],
    loadComponent: () =>
      import("./projects/projects.component").then(
        (comp) => comp.ProjectsComponent
      ),
    children: [
      {
        path: "projects/:id",
        loadComponent: () =>
          import("./projects/[project-id]/project-view.component").then(
            (comp) => comp.DeviceMainViewComponent
          ),
        // Make sure that the project is saved before going to a new page
        canDeactivate: [SaveProjectGuard],
      },
      {
        path: "",
        loadComponent: () =>
          import("./projects/list/project-list.component").then(
            (comp) => comp.ProjectListComponent
          ),
      },
      {
        // FIXME: Shouldn't this route be publicly available?
        path: "information",
        loadComponent: () =>
          import("./shared/information-page/information-page.component").then(
            (comp) => comp.InformationPageComponent
          ),
      },
    ],
  },
  {
    path: "register",
    canActivate: [IsAuthenticatedGuard],
    loadComponent: () =>
      import("./lib/register/register.component").then(
        (comp) => comp.RegisterComponent
      ),
  },
  {
    // Catch all router - used as 404 path
    path: "**",
    loadComponent: () =>
      import("./lib/page-not-found/page-not-found.component").then(
        (comp) => comp.PageNotFoundComponent
      ),
  },
];
