import { NgIf } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input, Output
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatFormField, MatSuffix } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import { ComponentsHeaderComponent } from "../../common/components-header/components-header.component";
import { InputComponent } from "../../../../packages/ui/src/primitives/input/input.component";
import { ButtonComponent } from "./../../../../packages/ui/src/primitives/button/button.component";
import { SearchIconComponent } from "./../../icons/search-icon/search-icon.component";
import { CloseIconComponent } from "../../icons/close-icon/close-icon.component";
import { PlusIconComponent } from "../../icons/plus-icon/plus-icon.component";
import { UploadIconComponent } from "./../../icons/upload-icon/upload-icon.component";
import { HomeIconComponent } from "./../../icons/home-icon/home-icon.component";

@Component({
  selector: "app-project-header",
  templateUrl: "./project-header.component.html",
  styleUrls: ["./project-header.component.css"],
  standalone: true,
  imports: [
    ComponentsHeaderComponent,
    NgIf,
    MatFormField,
    MatInput,
    FormsModule,
    MatIcon,
    MatSuffix,
    TranslateModule,
    InputComponent,
    ButtonComponent,
    SearchIconComponent,
    CloseIconComponent,
    UploadIconComponent,
    PlusIconComponent,
    HomeIconComponent,
  ],
})
export class ProjectHeaderComponent {
  @Input() isShowSubHeader: boolean;
  @Input() searchText: string;
  @Output() emitCreateProjectEvent = new EventEmitter();
  @Output() emitUploadProjectEvent = new EventEmitter();
  @Output() emitSearchTextEvent = new EventEmitter();

  constructor() {}
  OnCreateProject() {
    this.emitCreateProjectEvent.emit("");
  }
  onUploadProject() {
    this.emitUploadProjectEvent.emit("true");
  }
  onSearchProject(event) {
    this.emitSearchTextEvent.emit(this.searchText);
  }
  clearSearchText() {
    this.searchText = "";
    this.emitSearchTextEvent.emit(this.searchText);
  }
}
