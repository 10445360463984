import { NONE_TYPE } from "@angular/compiler";
import axios, { AxiosError } from "axios";
import DriveProjectAttribute from "../../../app/models/drive/driveProjectAttribute";
import ProjectInfo from "../../../app/models/drive/projectInfo";
import AxiosClient from "../client/AxiosClient";
import DriveFileResponse from "../file/DriveFileResponse";
import ListEntry from "../../models/devices/ListEntry";
import { ResultType } from "../models/Result";
import { serviceFactory } from "../serviceLayer/servicefactory/serviceFactory";
import serviceUrlConstants from "../serviceLayer/serviceUrlConstants";
import { DriveProjectAttributeNames } from "./constants/DriveProjectAttributeNames";
import { TRawProject } from "./types";
import { TProject } from "src/app/projects/list/project-list.service";

/* eslint-disable no-fallthrough */
export default class ProjectService {
  error: null;
  token: string;
  fileName: string;
  constructor() {}

  //TODO::Folder Id Removal Migration
  async setProjectInfoInSession(projectId: string) {
    let url = serviceUrlConstants.getRefreshSessionUrl(projectId);

    const config2 = await this.getHeader();
    await AxiosClient.post(url, config2, "")
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS:
            return response;
          case ResultType.FAIL: {
            throw response.message;
          }
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  async getHeaderConfig(contentType = "application/vnd.api+json") {
    const config = {
      headers: await AxiosClient.GetConfigurationHeader(
        this.token,
        contentType
      ),
    };
    return config;
  }

  async getHeader() {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": localStorage.i18nextLng,
        // Cookie: document.cookie,
      },
    };
    return config;
  }

  /**
   * Fetches all projects from SiDrive.
   */
  async getAllProjects(): Promise<TProject[]> {
    const url = serviceUrlConstants.getAllProjects();

    try {
      const result = await AxiosClient.get(url, NONE_TYPE);

      console.log(result);

      if (result.type === ResultType.SUCCESS) {
        return result.value.map((project: TRawProject) => ({
          projectId: project.projectId,
          projectName: project.projectName,
          projectDescription: project?.projectDescription?.value,
          customer: project?.client?.value,
          country: project?.country?.value,
          office: project?.designOffice?.value,
          location: project?.location?.value,
          comment: project?.comment?.value ?? "",
          createdAt: new Date(project?.createdDate),
          updatedAt: new Date(project?.lastUpdatedDate),
        }));
      } else {
        throw result.message;
      }
    } catch (error) {
      throw error;
    }
  }

  async getHeaderInfo(contentType: string = "application/vnd.api+json") {
    let headerConfig = await this.getHeaderConfig(contentType);
    // console.log(headerConfig, "headerConfig");
    return headerConfig;
  }

  async getProjects(
    queryParams?: string,
    isSearchEnabled?: boolean
  ): Promise<ProjectInfo[]> {
    let subProjectList = new Array<ProjectInfo>();
    const config2 = await this.getHeader();
    // alert(JSON.stringify(config));
    // console.log("getallprojects");
    const url = serviceUrlConstants.getDriveProjectUrl(
      queryParams,
      isSearchEnabled
    );
    //  .concat("?sort=-createdDate&include=projectAttributes");
    await AxiosClient.get(url, config2)
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            let projectInfoList: ProjectInfo[] = [];
            response.value.forEach((element) => {
              let projectInfo: ProjectInfo = new ProjectInfo();
              projectInfo = element;
              projectInfo.id = element.projectId;
              projectInfo.name = element.projectName;
              projectInfoList.push(projectInfo);
            });
            subProjectList = projectInfoList;
            return subProjectList;
          }
          case ResultType.FAIL: {
            throw response.message;
          }
        }
      })
      .catch((error) => {
        throw error;
      });
    return subProjectList;
  }

  getSearchFilterQuery(searchText: string) {
    return '&filter={"OR":[{ "AND":[{ "OR":[ { "AND":[ {"projectAttributes":{ "name":"OrderState" } }, { "projectAttributes": { "value":"Offered"} } ] }, { "AND":[ { "projectAttributes": { "name":"OrderState"} }, { "projectAttributes": { "value":"Ordered" } } ] } ] }, { "AND":[ {"LIKE":{"documents":{ "id":"%25'
      .concat(searchText)
      .concat(
        '%25"} }},{"LIKE":{"documents":{"name":"%25.sc" }}}]}]},{"LIKE":{"globalCartId":"%25'
      )
      .concat(searchText)
      .concat('%25" }}]}&include=parentProject,projectAttributes,documents');
  }

  async updateProject(project: ProjectInfo): Promise<ProjectInfo> {
    const apiurl = serviceUrlConstants.getUpdateProjectUrl(project.id);
    if (project.id !== undefined) {
      project["projectName"] = project.name;
      project["projectId"] = project.id;
      try {
        const config2 = await this.getHeader();
        await AxiosClient.put(apiurl, config2, project)
          .then(async (response) => {
            switch (response.type) {
              case ResultType.SUCCESS: {
                project.id = response.value.projectId;
                project.name = response.value.projectName;
                project.lastUpdatedDate = response.value.lastUpdatedDate;
                let projectAttributes: DriveProjectAttribute[] =
                  await this.mapProjectToProjectAttributes(project);
                for (let index = 0; index < projectAttributes.length; index++) {
                  project = await this.mapProjectAttributesToProject(
                    projectAttributes[index],
                    project,
                    projectAttributes[index].id
                  );
                }
                return project;
              }
              case ResultType.FAIL:
                throw response.message;
            }
          })
          .catch((error) => {
            throw error;
          })
          .finally(async () => {});
      } catch (error) {
        throw error;
      }
    }
    return project;
  }
  //TODO::Folder Id Removal Migration
  async getProjectByID(projectId: string): Promise<ProjectInfo> {
    let projectInfo = new ProjectInfo();
    try {
      const apiurl = serviceUrlConstants.getDriveProjectInfoUrl(projectId);

      const config2 = await this.getHeader();
      await AxiosClient.get(apiurl, config2)
        .then((filteredresponse) => {
          switch (filteredresponse.type) {
            case ResultType.SUCCESS: {
              projectInfo = this.checkEmptyAttributes(filteredresponse.value);
              projectInfo.autoSaveUrl = filteredresponse.value.autoSaveUrl;
              projectInfo.configurationUrl =
                filteredresponse.value.configurationUrl;
              projectInfo.name = filteredresponse.value.projectName;
              projectInfo.id = filteredresponse.value.projectId;
              projectInfo.createdDate = filteredresponse.value.createdDate;
              projectInfo.latestConfig = filteredresponse.value.latestConfig;
              projectInfo.createdBy = filteredresponse.value.createdBy;
              projectInfo.planner = filteredresponse.value.planner;
              projectInfo.browserUUID = filteredresponse.value.browserUUID;
              projectInfo.standard = filteredresponse.value.standard;
              projectInfo.isLocked = filteredresponse.value.locked;
              return projectInfo;
            }
            case ResultType.FAIL:
              throw filteredresponse.message;
          }
        })
        .catch((error) => {
          throw error;
        });
    } catch (e) {
      throw e;
    }
    return projectInfo;
  }

  mapProjectAttributesToProject(
    attributeDetails: any,
    projectInfo: ProjectInfo,
    attributeId: any
  ) {
    if (
      attributeDetails.name === DriveProjectAttributeNames.PROJECT_DESCRIPTION
    ) {
      projectInfo.projectDescription = attributeDetails;
      projectInfo.projectDescription.id = attributeId;
    } else if (attributeDetails.name === DriveProjectAttributeNames.STANDARD) {
      projectInfo.standard = attributeDetails;
      projectInfo.standard.id = attributeId;
    } else if (attributeDetails.name === DriveProjectAttributeNames.PLANNER) {
      projectInfo.planner = attributeDetails;
      projectInfo.planner.id = attributeId;
    } else if (attributeDetails.name === DriveProjectAttributeNames.LOCATION) {
      projectInfo.location = attributeDetails;
      projectInfo.location.id = attributeId;
    } else if (
      attributeDetails.name === DriveProjectAttributeNames.DESIGN_OFFICE
    ) {
      projectInfo.designOffice = attributeDetails;
      projectInfo.designOffice.id = attributeId;
    } else if (attributeDetails.name === DriveProjectAttributeNames.CLIENT) {
      projectInfo.client = attributeDetails;
      projectInfo.client.id = attributeId;
    } else if (attributeDetails.name === DriveProjectAttributeNames.LANGUAGE) {
      projectInfo.language = attributeDetails;
      projectInfo.language.id = attributeId;
    }
    //  console.log(projectInfo, "projectInfo in mapper");
    return projectInfo;
  }

  mapeProjectAttributesToProject(
    attributeDetails: any,
    projectInfo: ProjectInfo,
    attributeId: any
  ) {
    // console.log(attributeId, "attributeId", attributeDetails);
    if (
      attributeDetails.attributes.name ===
      DriveProjectAttributeNames.PROJECT_DESCRIPTION
    ) {
      // console.log(projectInfo, "*********");
      projectInfo.projectDescription = attributeDetails.attributes;
      // console.log(projectInfo, "*********");
      projectInfo.projectDescription.id = attributeId;
    } else if (
      attributeDetails.attributes.name === DriveProjectAttributeNames.STANDARD
    ) {
      projectInfo.standard = attributeDetails.attributes;
      projectInfo.standard.id = attributeId;
    } else if (
      attributeDetails.attributes.name === DriveProjectAttributeNames.PLANNER
    ) {
      projectInfo.planner = attributeDetails.attributes;
      projectInfo.planner.id = attributeId;
    } else if (
      attributeDetails.attributes.name === DriveProjectAttributeNames.LOCATION
    ) {
      projectInfo.location = attributeDetails.attributes;
      projectInfo.location.id = attributeId;
    } else if (
      attributeDetails.attributes.name ===
      DriveProjectAttributeNames.DESIGN_OFFICE
    ) {
      projectInfo.designOffice = attributeDetails.attributes;
      projectInfo.designOffice.id = attributeId;
    } else if (
      attributeDetails.attributes.name === DriveProjectAttributeNames.CLIENT
    ) {
      projectInfo.client = attributeDetails.attributes;
      projectInfo.client.id = attributeId;
    } else if (
      attributeDetails.attributes.name === DriveProjectAttributeNames.LANGUAGE
    ) {
      projectInfo.language = attributeDetails.attributes;
      projectInfo.language.id = attributeId;
    } else if (
      attributeDetails.attributes.name === DriveProjectAttributeNames.COUNTRY
    ) {
      projectInfo.country = attributeDetails.attributes;
      projectInfo.country.id = attributeId;
    }
    // console.log(projectInfo, "projectInfo in mapper");
    return projectInfo;
  }
  //TODO::Folder Id Removal Migration
  async deleteProject(projectId: string): Promise<Boolean> {
    let Result: any;
    const apiurl = serviceUrlConstants.deleteProjectUrl(projectId);

    const config2 = await this.getHeader();
    await AxiosClient.delete(apiurl, config2)
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS:
            // eslint-disable-next-line no-lone-blocks
            return true;
          case ResultType.FAIL:
            throw response.message;
        }
      })
      .catch(async (error: AxiosError) => {
        throw error;
      });
    return true;
  }

  async addProject(project: ProjectInfo): Promise<ProjectInfo> {
    // let lockInfo: LockInfo;
    let projectInfo: ProjectInfo = new ProjectInfo();
    project["projectName"] = project.name;
    try {
      const config3 = await this.getHeader();
      await AxiosClient.post(
        serviceUrlConstants.createProjectUrl(),
        config3,
        project
      )
        .then(async (response) => {
          switch (response.type) {
            case ResultType.SUCCESS:
              // eslint-disable-next-line no-lone-blocks
              {
                projectInfo = response.value;
                projectInfo = this.checkEmptyAttributes(projectInfo);
                projectInfo.id = response.value.projectId;
                projectInfo.name = response.value.projectName;
              }
              break;
            case ResultType.FAIL:
              throw response.message;
              break;
          }
        })
        .catch((error) => {
          throw error;
        });
    } catch (e) {
      console.error(e);
      this.error = e;
    }
    return projectInfo;
  }
  checkEmptyAttributes(projectInfo: ProjectInfo): ProjectInfo {
    projectInfo.client = !projectInfo.client
      ? this.setDefaultAttribute(DriveProjectAttributeNames.CLIENT)
      : projectInfo.client;
    projectInfo.country = !projectInfo.country
      ? this.setDefaultAttribute(DriveProjectAttributeNames.COUNTRY)
      : projectInfo.country;
    projectInfo.designOffice = !projectInfo.designOffice
      ? this.setDefaultAttribute(DriveProjectAttributeNames.DESIGN_OFFICE)
      : projectInfo.designOffice;
    projectInfo.language = !projectInfo.language
      ? this.setDefaultAttribute(DriveProjectAttributeNames.LANGUAGE)
      : projectInfo.language;
    projectInfo.location = !projectInfo.location
      ? this.setDefaultAttribute(DriveProjectAttributeNames.LOCATION)
      : projectInfo.location;
    projectInfo.projectDescription = !projectInfo.projectDescription
      ? this.setDefaultAttribute(DriveProjectAttributeNames.PROJECT_DESCRIPTION)
      : projectInfo.projectDescription;
    projectInfo.comment = !projectInfo.comment
      ? this.setDefaultAttribute(DriveProjectAttributeNames.COMMENT)
      : projectInfo.comment;
    return projectInfo;
  }
  setDefaultAttribute(attribute: string): DriveProjectAttribute {
    let projectAttribute: DriveProjectAttribute;
    projectAttribute.name = attribute;
    projectAttribute.value = "";
    return projectAttribute;
  }
  mapProjectToProjectAttributes(project: ProjectInfo): DriveProjectAttribute[] {
    let projectAttributes: DriveProjectAttribute[] = [];
    if (project != null || project != undefined) {
      if (project.projectDescription) {
        project.projectDescription = this.createProjectAttribute(
          DriveProjectAttributeNames.PROJECT_DESCRIPTION,
          project.projectDescription.value,
          project.projectDescription.id
        );
        projectAttributes.push(project.projectDescription);
      } else {
        project.projectDescription = this.createProjectAttribute(
          DriveProjectAttributeNames.PROJECT_DESCRIPTION,
          ""
        );
        projectAttributes.push(project.projectDescription);
      }
      if (project.planner) {
        project.planner = this.createProjectAttribute(
          DriveProjectAttributeNames.PLANNER,
          project.planner.value,
          project.planner.id
        );
        projectAttributes.push(project.planner);
      }
      if (project.standard) {
        project.standard = this.createProjectAttribute(
          DriveProjectAttributeNames.STANDARD,
          project.standard.value,
          project.standard.id
        );
        projectAttributes.push(project.standard);
      }
      if (project.location) {
        project.location = this.createProjectAttribute(
          DriveProjectAttributeNames.LOCATION,
          project.location.value,
          project.location.id
        );
        projectAttributes.push(project.location);
      } else {
        project.location = this.createProjectAttribute(
          DriveProjectAttributeNames.LOCATION,
          ""
        );
        projectAttributes.push(project.location);
      }
      if (project.client) {
        project.client = this.createProjectAttribute(
          DriveProjectAttributeNames.CLIENT,
          project.client.value,
          project.client.id
        );
        projectAttributes.push(project.client);
      } else {
        project.client = this.createProjectAttribute(
          DriveProjectAttributeNames.CLIENT,
          ""
        );
        projectAttributes.push(project.client);
      }
      if (project.designOffice) {
        project.designOffice = this.createProjectAttribute(
          DriveProjectAttributeNames.DESIGN_OFFICE,
          project.designOffice.value,
          project.designOffice.id
        );
        projectAttributes.push(project.designOffice);
      } else {
        project.designOffice = this.createProjectAttribute(
          DriveProjectAttributeNames.DESIGN_OFFICE,
          ""
        );
        projectAttributes.push(project.designOffice);
      }
      if (project.country) {
        project.country = this.createProjectAttribute(
          DriveProjectAttributeNames.COUNTRY,
          project.country.value,
          project.country.id
        );
        projectAttributes.push(project.country);
      } else {
        project.country = this.createProjectAttribute(
          DriveProjectAttributeNames.COUNTRY,
          ""
        );
        projectAttributes.push(project.country);
      }
      if (project.language) {
        project.language = this.createProjectAttribute(
          DriveProjectAttributeNames.LANGUAGE,
          project.language.value,
          project.language.id
        );
        projectAttributes.push(project.language);
      } else {
        project.language = this.createProjectAttribute(
          DriveProjectAttributeNames.LANGUAGE,
          ""
        );
        projectAttributes.push(project.language);
      }
    }
    return projectAttributes;
  }

  mapSubProjectAttributeToSubProject(
    projectInfo: ProjectInfo,
    response: any
  ): ProjectInfo {
    if (response.attributes.name === DriveProjectAttributeNames.CLIENT) {
      projectInfo.client.id = response.id;
      projectInfo.client.locale = response.attributes.locale;
      projectInfo.client.name = response.attributes.name;
      projectInfo.client.value = response.attributes.value;
      projectInfo.client.status = response.attributes.status;
    } else if (
      response.attributes.name === DriveProjectAttributeNames.COUNTRY
    ) {
      projectInfo.country.id = response.id;
      projectInfo.country.locale = response.attributes.locale;
      projectInfo.country.name = response.attributes.name;
      projectInfo.country.value = response.attributes.value;
      projectInfo.country.status = response.attributes.status;
    } else if (
      response.attributes.name === DriveProjectAttributeNames.DESIGN_OFFICE
    ) {
      projectInfo.designOffice.id = response.id;
      projectInfo.designOffice.locale = response.attributes.locale;
      projectInfo.designOffice.name = response.attributes.name;
      projectInfo.designOffice.value = response.attributes.value;
      projectInfo.designOffice.status = response.attributes.status;
    } else if (
      response.attributes.name === DriveProjectAttributeNames.LANGUAGE
    ) {
      projectInfo.language.id = response.id;
      projectInfo.language.locale = response.attributes.locale;
      projectInfo.language.name = response.attributes.name;
      projectInfo.language.value = response.attributes.value;
      projectInfo.language.status = response.attributes.status;
    } else if (
      response.attributes.name === DriveProjectAttributeNames.LOCATION
    ) {
      projectInfo.location.id = response.id;
      projectInfo.location.locale = response.attributes.locale;
      projectInfo.location.name = response.attributes.name;
      projectInfo.location.value = response.attributes.value;
      projectInfo.location.status = response.attributes.status;
    } else if (
      response.attributes.name === DriveProjectAttributeNames.PLANNER
    ) {
      projectInfo.planner.id = response.id;
      projectInfo.planner.locale = response.attributes.locale;
      projectInfo.planner.name = response.attributes.name;
      projectInfo.planner.value = response.attributes.value;
      projectInfo.planner.status = response.attributes.status;
    } else if (
      response.attributes.name ===
      DriveProjectAttributeNames.PROJECT_DESCRIPTION
    ) {
      projectInfo.projectDescription.id = response.id;
      projectInfo.projectDescription.locale = response.attributes.locale;
      projectInfo.projectDescription.name = response.attributes.name;
      projectInfo.projectDescription.value = response.attributes.value;
      projectInfo.projectDescription.status = response.attributes.status;
    } else if (
      response.attributes.name === DriveProjectAttributeNames.STANDARD
    ) {
      projectInfo.standard.id = response.id;
      projectInfo.standard.locale = response.attributes.locale;
      projectInfo.standard.name = response.attributes.name;
      projectInfo.standard.value = response.attributes.value;
      projectInfo.standard.status = response.attributes.status;
    }
    return projectInfo;
  }

  createProjectAttribute(
    attributeName: string,
    attributeValue: string,
    attributeId: string = ""
  ): DriveProjectAttribute {
    const projectAttribute = new DriveProjectAttribute();
    projectAttribute.id = attributeId;
    projectAttribute.inputType = "text";
    projectAttribute.name = attributeName;
    projectAttribute.locale = "EN";
    projectAttribute.status = "ACTIVE";
    projectAttribute.value = attributeValue;
    return projectAttribute;
  }
  //TODO::Folder Id Removal Migration
  async uploadFile(file: File, driveProjectId: string): Promise<ProjectInfo> {
    let projectResponse: ProjectInfo = null;
    await serviceFactory.FileImportService.uploadFile(file, driveProjectId)
      .then((projectInfo) => {
        // console.log(projectInfo, "projectResponse");
        projectResponse = projectInfo;
        return projectResponse;
      })
      .catch((e) => {
        throw e;
      });
    return projectResponse;
  }
  //TODO::Folder Id Removal Migration
  async uploadFileToDrive(
    driveProjectId: string,
    fileName: string = "",
    isAutoSaveOnBufferTime?: boolean
  ): Promise<DriveFileResponse> {
    // console.log(driveProjectId, "driveProjectId");
    // let lockInfo: LockInfo;
    await serviceFactory.FileImportService.uploadFileToDrive(
      driveProjectId,
      fileName,
      isAutoSaveOnBufferTime
    )
      .then((projectInfo) => {
        return projectInfo;
      })
      .catch((e) => {
        throw e;
      })
      .finally(() => {});
    return null;
  }
  //TODO::Folder Id Removal Migration
  async getImportedDevices(
    projectid: string,
    name: string
  ): Promise<ListEntry[]> {
    let listenteriesmodels: ListEntry[];
    await serviceFactory.ProductService.getImportedDevices(projectid, name)
      .then((listEntries) => {
        listenteriesmodels = listEntries;
        return listEntries;
      })
      .catch((e) => {
        throw e;
      });
    return listenteriesmodels;
  }
  //TODO::Folder Id Removal Migration
  async saveConfiguration(
    driveProjectId: string,
    fileName: string,
    isAutoSaveOnBufferTime?: boolean
  ): Promise<any> {
    let configUrl = serviceUrlConstants.getDownloadUrl();
    // await serviceFactory.FileImportService.importFile(
    //   configUrl
    // ).then(async response => {
    // if (response) {
    await this.uploadFileToDrive(
      driveProjectId,
      fileName,
      isAutoSaveOnBufferTime
    )
      .then((driveResponse) => {})
      .catch((e) => {
        throw e;
      });
    //   }
    // });
  }
  //TODO::Folder Id Removal Migration
  async exportProject(projectId: string, name: string) {
    let url = serviceUrlConstants.getExportProjectUrl(projectId);
    await AxiosClient.get(url, "", "arraybuffer")
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            const blob = new Blob([response.value]);
            let url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            // link.download = this.fileName.concat(".pdf");

            link.href = url;
            link.setAttribute("download", name.concat(".pdf"));
            document.body.appendChild(link);
            link.click();
            return response.value;
          }
          case ResultType.FAIL:
            throw response.message;
        }
      })
      .catch((err: AxiosError) => {
        throw err;
      });
  }
  //TODO::Folder Id Removal Migration
  async downloadProject(projectId: string, name: string): Promise<any> {
    let url = serviceUrlConstants.getDownloadProjectUrl(projectId);
    const config4 = await this.getHeader();
    await axios
      .get(url, {
        responseType: "arraybuffer",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Accept-Language": localStorage.i18nextLng,
          // Cookie: document.cookie,
        },
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: ".scu" });
        let url = (window.URL || window.webkitURL).createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "curves.scu";
        link.href = url;
        link.setAttribute("download", name.concat(".scu"));
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        throw e;
      });
  }
  async logout(): Promise<Boolean> {
    const url = serviceUrlConstants.getLogoutUrl();
    const config5 = await this.getHeader();

    try {
      await AxiosClient.post(url, config5, NONE_TYPE)
        .then((response) => {
          switch (response.type) {
            case ResultType.SUCCESS: {
              return true;
            }
            case ResultType.FAIL: {
              throw response.message;
            }
          }
        })
        .catch((error) => {
          throw error;
        })
        .finally(async () => {});
    } catch (error) {
      throw error;
    }
    return true;
  }

  async getCountry(): Promise<string[]> {
    const url = serviceUrlConstants.getCountryUrl();
    let countryList = [];
    const config5 = await this.getHeader();
    await AxiosClient.get(url, config5)
      .then((response) => {
        switch (response.type) {
          case ResultType.SUCCESS: {
            countryList = response.value;
            return countryList;
          }
          case ResultType.FAIL: {
            throw response;
          }
        }
      })
      .catch((error) => {
        throw error;
      })
      .finally(async () => {});
    return countryList;
  }
}
