import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-setting-icon",
    templateUrl: "./setting-icon.component.html",
    standalone: true,
})
export class SettingIconComponent {
  constructor() {}
}
