import { Component, Input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { NgIf } from "@angular/common";

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.css"],
  standalone: true,
  imports: [NgIf, TranslateModule],
})
export class SpinnerComponent {
  @Input() spinnerText: string;
  constructor() {}
}
