<!-- information overlay -->
<ul>
  <li class="d-flex flex-column">
    <div>SIMARIS curves</div>
    <small class="lh-sm">
      {{ version }}
    </small>
  </li>
  <hr />
  @for (information of informationList; track information) {
    <li>
      <a
        [href]="information.url | translate"
        target="_blank"
        class="d-flex align-items-center"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style="margin-right: 8px"
        >
          <path
            d="M8.00004 5V7H15.58L5.29004 17.29L6.71004 18.71L17 8.41V16H19V5H8.00004Z"
            fill="#000028"
          /></svg
        >{{ information.displayName | translate }}</a
      >
    </li>
  }
</ul>
