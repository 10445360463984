<div class="h-100 w-100 d-flex flex-column" *ngIf="data.length; else elseBlock">
  <div class="d-flex flex-row pt-3" style="height: 90%">
    <div class="h-100 w-50 d-flex flex-column ps-3">
      <div>
        <img class="w-100" *ngIf="productImgUrl" [src]="productImgUrl" />
      </div>
      <div
        class="h-25 d-flex flex-column justify-content-between mt-3"
        *ngIf="selectedNode && selectedNode.name"
      >
        <div class="prd-name_highlight ps-2">
          <h3>{{ selectedNode.name }}</h3>
        </div>
        <h2
          *ngIf="
            selectedNode &&
            selectedNode.name &&
            data.length > 0 &&
            catalogEnvironmentVariables
          "
        >
          <span *ngIf="catalogEnvironmentVariables.mlfb.includes('_')">
            <img height="20" width="18" src="../../assets/icon/warning.svg" />
          </span>
          {{ catalogEnvironmentVariables.mlfb }}
        </h2>
      </div>
    </div>
    <div class="w-50 ps-4">
      <div
        class="d-flex justify-content-between align-items-end"
        *ngIf="selectedNode && selectedNode.name && data.length > 0"
      >
        <h4>{{ "CatalogPropertyControl_AttributeValue" | translate }}</h4>
        <button
          ui-button
          size="icon-small"
          variant="tertiary"
          appTooltip
          tooltip="{{ 'CatalogSearchControl_ResetLink' | translate }}"
          (click)="resetAttributes()"
        >
          <app-reset-icon />
        </button>
      </div>
      <div class="attributes-list">
        <div
          *ngFor="let d of data; index as i"
          class="w-100 d-flex align-items-center flex-row"
        >
          <div class="pt-2 pe-2" style="width: 1.5rem">
            <img
              *ngIf="!d.requested_value.displayValue"
              height="20"
              width="18"
              src="../../assets/icon/warning.svg"
            />
          </div>
          <div class="w-100 me-3">
            <label>
              {{ d.key }} <span *ngIf="d.unit">[{{ d.unit }}]</span>
            </label>
            <mat-form-field
              appearance="outline"
              id="{{ 'attribute' + i }}"
              data-testid="{{ 'attribute' + i }}"
              class="w-100"
              *ngIf="
                d.values ||
                (d.values &&
                  d.requested_value &&
                  d.requested_value.displayValue)
              "
            >
              <mat-select
                [(ngModel)]="d.requested_value.displayValue"
                disableOptionCentering
                panelClass="attributes-panel-class"
              >
                {{ val }}
                <mat-option
                  *ngFor="let val of d.values"
                  [value]="val.displayValue"
                  (click)="onAttributesSelection(d, val)"
                  [ngClass]="
                    val.type === CONSTANT.INVALID_VALUE
                      ? 'attributes__invalid-values'
                      : val.type === CONSTANT.REQUESTED_VALUE
                        ? 'attributes__requested-value'
                        : ''
                  "
                  >{{ val.displayValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <input
              ui-input
              readonly="true"
              *ngIf="d.requested_value && !d.values"
              type="text"
              [value]="d.requested_value.displayValue"
              class="w-100"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="d-flex justify-content-end gap-3 align-items-center"
    *ngIf="selectedNode && selectedNode.name && data.length > 0"
  >
    <button ui-button variant="secondary" (click)="onCancelCatalog()">
      {{ "CANCEL" | translate }}
    </button>
    <button
      ui-button
      (click)="addOrderNumber()"
      [disabled]="!isValidOrderNumber || !isGetMlfbCompleted"
    >
      {{ mode | translate }}
    </button>
  </div>
</div>
<ng-template #elseBlock>
  <div
    class="h-100 w-100 d-flex justify-content-between align-items-center p-4"
  >
    <p>{{ "CatalogView_hint" | translate }}</p>
  </div>
</ng-template>
