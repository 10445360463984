import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from "@angular/core";

@Directive({
    selector: "[appTooltip]",
    standalone: true,
})
export class TooltipDirective {
  @Input() tooltip: string;
  @Input() placement: string = "bottom";
  @Input() leftOffSet: string = "0";
  delay: string = "500";
  tooltipEle: HTMLElement;
  offset = 10;
  constructor(private eleRef: ElementRef, private renderer: Renderer2) {}
  @HostListener("mouseenter") onMouseEnter() {
    if (!this.tooltipEle) {
      this.show();
    }
  }

  @HostListener("mouseleave") onMouseLeave() {
    if (this.tooltipEle) {
      this.hide();
    }
  }

  show() {
    const prevTooltip = document.getElementById("custom-tooltip");
    if (prevTooltip) {
      prevTooltip.remove();
    }
    this.create();
    this.setPosition();
    this.renderer.addClass(this.tooltipEle, "ng-tooltip-show");
  }

  hide() {
    this.renderer.removeClass(this.tooltipEle, "ng-tooltip-show");
    window.setTimeout(() => {
      this.renderer.removeChild(document.body, this.tooltipEle);
      this.tooltipEle = null;
    }, 100);
  }

  create() {
    this.tooltipEle = this.renderer.createElement("span");

    this.renderer.appendChild(
      this.tooltipEle,
      this.renderer.createText(this.tooltip) // textNode
    );

    this.renderer.appendChild(document.body, this.tooltipEle);

    this.renderer.addClass(this.tooltipEle, "ng-tooltip");
    this.renderer.addClass(this.tooltipEle, `ng-tooltip-${this.placement}`);
    this.renderer.setAttribute(this.tooltipEle, "id", "custom-tooltip");

    this.renderer.setStyle(
      this.tooltipEle,
      "-webkit-transition",
      `opacity ${this.delay}ms`
    );
    this.renderer.setStyle(
      this.tooltipEle,
      "-moz-transition",
      `opacity ${this.delay}ms`
    );
    this.renderer.setStyle(
      this.tooltipEle,
      "-o-transition",
      `opacity ${this.delay}ms`
    );
    this.renderer.setStyle(
      this.tooltipEle,
      "transition",
      `opacity ${this.delay}ms`
    );
  }

  setPosition() {
    const hostPos = this.eleRef.nativeElement.getBoundingClientRect();

    const tooltipPos = this.tooltipEle.getBoundingClientRect();

    const scrollPos =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    let top, left;

    if (this.placement === "top") {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === "bottom") {
      top = hostPos.bottom + this.offset;
      left =
        hostPos.left +
        (hostPos.width - tooltipPos.width) / 2 +
        parseInt(this.leftOffSet);
    }

    if (this.placement === "left") {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset;
    }

    if (this.placement === "right") {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset;
    }

    this.renderer.setStyle(this.tooltipEle, "top", `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltipEle, "left", `${left}px`);
  }
}
