<div class="d-flex justify-content-start align-items-center">
  @for (characteristic of deviceSetting.radioActions; track characteristic) {
    <input
      ui-radio
      type="radio"
      [value]="characteristic"
      [name]="deviceSetting.curveType"
      [checked]="isChecked(characteristic)"
      (click)="onRadioSelection(characteristic)"
      [disabled]="!deviceSetting.isEngaged"
    />
    <label class="p-2">{{ characteristic | translate }}</label>
  }
</div>
