import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import { CustomLoader } from "../language/custom.loader";
import { LanguageMissingTranslationHandler } from "../language/language.missing-translation-handler";
import { FormatTimePipe } from "../services/pipes/format-time.pipe";
import { InformationPageComponent } from "./information-page/information-page.component";
import { HeaderComponent } from "../lib/header/header.component";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { ProjectsModule } from "../project-main-view/projects.module";
import { CommonHelperModule } from "../common/common-helper.module";
@NgModule({
  imports: [
    CommonModule,
    CommonHelperModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    FormsModule,
    MatSelectModule,
    MatTooltipModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: LanguageMissingTranslationHandler,
      },
    }),
    ProjectsModule,
    HeaderComponent,
    FormatTimePipe,
    InformationPageComponent,
    ConfirmationDialogComponent,
  ],
  exports: [
    HeaderComponent,
    FormatTimePipe,
    ProjectsModule,
    InformationPageComponent,
    ConfirmationDialogComponent,
    CommonHelperModule,
  ],
  providers: [ConfirmationDialogComponent],
  bootstrap: [],
})
export class SharedModule {}
