import { Component } from "@angular/core";
import { environment } from "../../../environments/environment";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { INFORMATIONCONSTANTS } from "../../models/constants/InfoPageConstants";

@Component({
  selector: "app-information-page",
  templateUrl: "./information-page.component.html",
  styleUrls: ["./information-page.component.css"],
  standalone: true,
  imports: [TranslateModule],
})
export class InformationPageComponent {
  year = new Date().getFullYear();
  version: string = "0.0";
  informationList = INFORMATIONCONSTANTS;
  constructor(private translator: TranslateService) {
    this.version = environment.appVersion;
  }
}
