import { NgClass, NgIf } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import { NgEventBus } from "ng-event-bus";
import { MetaData } from "ng-event-bus/lib/meta-data";
import { ButtonComponent } from "../../../packages/ui/src/primitives/button/button.component";
import { Subscription } from "rxjs";
import { InputComponent } from "../../../packages/ui/src/primitives/input/input.component";
import { TooltipDirective } from "../common/tooltip.directive";
import { EventType } from "../models/constants/eventType";
import { MarkViewType } from "../models/constants/mark-view-type";
import { MarkConstants } from "../models/constants/MarkConstants";
import MarkModel from "../models/marks/MarkModel";
import { serviceFactory } from "../services/serviceLayer/servicefactory/serviceFactory";
import { CloseIconComponent } from "../icons/close-icon/close-icon.component";
import { LinkIconComponent } from "../icons/link-icon/link-icon.component";

@Component({
  selector: "app-add-mark-component",
  templateUrl: "./add-mark-component.component.html",
  styleUrls: ["./add-mark-component.component.css"],
  standalone: true,
  imports: [
    NgClass,
    TooltipDirective,
    FormsModule,
    NgIf,
    TranslateModule,
    InputComponent,
    ButtonComponent,
    CloseIconComponent,
    LinkIconComponent,
  ],
})
export class AddMarkComponentComponent implements OnDestroy, OnInit {
  xLabel: string = MarkConstants.MARK_XLABEL;
  yLabel: string = MarkConstants.MARK_YLABEL;
  subscriptions: Subscription = new Subscription();
  isShowLinkUnlink: boolean = true;
  tooltipXVal: string;
  tooltipYVal: string;

  constructor(
    public dialogRef: MatDialogRef<AddMarkComponentComponent>,
    public eventBus: NgEventBus,
    @Inject(MAT_DIALOG_DATA) public data: MarkModel
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  ngOnInit() {
    this.tooltipXVal = serviceFactory.MarksHelper.getFormattedTooltip(
      MarkConstants.MIN_IA,
      MarkConstants.MAX_IA
    );
    this.tooltipYVal = serviceFactory.MarksHelper.getTooltip(this.data.view);
    this.yLabel = serviceFactory.MarksHelper.getYLabel(this.data.view);
  }
  cancelEvent() {
    this.dialogRef.close("");
  }
  proceed() {
    this.dialogRef.close(this.data);
  }
  close() {
    this.dialogRef.close();
  }

  linkChange() {
    this.isShowLinkUnlink = !this.isShowLinkUnlink;
  }
  //ToBe Refactored
  updatedTime(changedValue) {
    if (
      this.data.isMarkOnSeriesData &&
      !isNaN(changedValue) &&
      changedValue &&
      this.isShowLinkUnlink
    ) {
      this.eventBus.cast(EventType.LINK_MARK_VALUE_CHANGEX, changedValue);
      this.eventBus
        .on(EventType.UPDATED_VALUE)
        .subscribe((metadata: MetaData) => {
          if (metadata.data != 0 || !isNaN(metadata.data)) {
            this.data.time = metadata.data.toFixed(3);
          } else {
            this.data.time = metadata.data;
          }
        });
    } else if (!isNaN(changedValue) && changedValue) {
      this.data.current = changedValue;
    }
  }
  //FIXME: Should be refactored
  updateCurrent(changedValue) {
    if (
      this.data.isMarkOnSeriesData &&
      !isNaN(changedValue) &&
      changedValue &&
      this.isShowLinkUnlink
    ) {
      this.eventBus.cast(EventType.LINK_MARK_VALUE_CHANGEY, changedValue);
      this.eventBus
        .on(EventType.UPDATED_VALUE)
        .subscribe((metadata: MetaData) => {
          if (metadata.data != 0 || !isNaN(metadata.data)) {
            this.data.current = metadata.data.toFixed(3);
          } else {
            this.data.current = metadata.data;
          }
        });
    } else if (!isNaN(changedValue) && changedValue) {
      this.data.time = changedValue;
    }
  }
  isValidXYValue() {
    if (isNaN(this.data.current) && isNaN(this.data.time)) {
      return false;
    } else {
      switch (this.data.view) {
        case MarkViewType.TRIP:
          return (
            this.data.time >= MarkConstants.MIN_Ts &&
            this.data.time <= MarkConstants.MAX_Ts &&
            this.isCurrentValueValid()
          );
        case MarkViewType.CURRENT:
          return (
            this.data.time >= MarkConstants.MIN_IC &&
            this.data.time <= MarkConstants.MAX_IC &&
            this.isCurrentValueValid()
          );
        case MarkViewType.ENERGY:
          return (
            this.data.time >= MarkConstants.MIN_I2t &&
            this.data.time <= MarkConstants.MAX_I2t &&
            this.isCurrentValueValid()
          );
      }
    }
  }
  isCurrentValueValid() {
    return (
      this.data.current >= MarkConstants.MIN_IA &&
      this.data.current <= MarkConstants.MAX_IA
    );
  }
}
