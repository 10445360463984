<svg
  width="24"
  height="24"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g id="search-small">
    <path
      id="Icon"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 8.64711C3 5.5284 5.52765 3 8.64711 3C11.7666 3 14.2942 5.5284 14.2942 8.64711C14.2942 9.88557 13.8958 11.0308 13.2201 11.9615L16.9941 15.7355L15.7296 17L11.9547 13.2251C11.0252 13.8977 9.88256 14.2942 8.64711 14.2942C5.52765 14.2942 3 11.7666 3 8.64711ZM12.7883 8.64711C12.7883 6.36342 10.93 4.5059 8.64711 4.5059C6.36417 4.5059 4.5059 6.36342 4.5059 8.64711C4.5059 10.93 6.36417 12.7883 8.64711 12.7883C10.93 12.7883 12.7883 10.93 12.7883 8.64711Z"
      fill="currentColor"
    />
  </g>
</svg>
