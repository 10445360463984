import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-globe-icon",
    templateUrl: "./globe-icon.component.html",
    styles: [":host {display: grid; place-content: center;}"],
    standalone: true,
})
export class GlobeIconComponent {
  constructor() {}
}
