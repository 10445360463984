import { Options, NgxSliderModule } from "@angular-slider/ngx-slider";
import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { NgEventBus } from "ng-event-bus";
import BaseModel from "../../models/devices/BaseModel";
import DeviceSettingsModel from "../../models/device-settings/DeviceSettingsModel";
import SettingsSliderModel from "../../models/device-settings/DeviceSettingsSliderModel";
import { EventType } from "../../models/constants/eventType";
import { MatLabel } from "@angular/material/form-field";
import { NgClass } from "@angular/common";

@Component({
    selector: "app-setting-horizontal-slider",
    templateUrl: "./setting-horizontal-slider.component.html",
    styleUrls: ["./setting-horizontal-slider.component.css"],
    standalone: true,
    imports: [
        NgClass,
        NgxSliderModule,
        MatLabel,
    ],
})
export class SettingHorizontalSliderComponent implements OnInit, OnChanges {
  @Input() sliderDetails: SettingsSliderModel;
  @Input() productDetails: BaseModel;
  @Input() isDisable: boolean;

  constructor(private eventBus: NgEventBus) {}
  horizontal_options: Options = {
    floor: 0,
    ceil: 10,
    step: 1,
    disabled: false,
  };
  ngOnChanges() {
    if (
      this.sliderDetails.type === "HORIZONTAL_MULTIPLIER" &&
      this.sliderDetails.minValue &&
      this.sliderDetails.maxValue &&
      this.sliderDetails.steps
    ) {
      this.horizontal_options = Object.assign({}, this.horizontal_options, {
        floor: this.sliderDetails.minValue,
        ceil: this.sliderDetails.maxValue,
        step: this.sliderDetails.steps,
      });
    }
  }

  ngOnInit() {
    if (
      this.sliderDetails.minValue &&
      this.sliderDetails.maxValue &&
      this.sliderDetails.steps
    ) {
      this.horizontal_options.floor = this.sliderDetails.minValue;
      this.horizontal_options.ceil = this.sliderDetails.maxValue;
      this.horizontal_options.step = this.sliderDetails.steps;
    }
    this.horizontal_options.disabled = this.isDisable;
  }
  onInputValueChanged(value: string) {
    this.sliderDetails.inputValue = parseInt(value);
    this.onValueChanged(value);
  }
  onValueChanged(e) {
    if (this.sliderDetails.type === "HORIZONTAL_MULTIPLIER") {
      let data: DeviceSettingsModel = new DeviceSettingsModel();
      data.productDetails = this.productDetails;
      this.sliderDetails.isSliderValueChanged = true;
      data.currentTime.push(this.sliderDetails);
      this.eventBus.cast(EventType.EMIT_HORIZONTALSLIDER_VALUE_CHANGE, data);
    }
  }
  emitSliderChangesOnMouseLeave(event) {
    if (this.sliderDetails.isSliderValueChanged) {
      this.eventBus.cast(
        EventType.EMITSLIDERCHANGESONMOUSELEAVE,
        this.sliderDetails
      );
      this.sliderDetails.isSliderValueChanged = false;
    }
  }
}
