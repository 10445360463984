import { CdkListbox, CdkOption } from "@angular/cdk/listbox";
import { Overlay, OverlayModule } from "@angular/cdk/overlay";
import { AsyncPipe, LowerCasePipe, NgFor, NgIf } from "@angular/common";
import { Component } from "@angular/core";
import { MatOption } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatFormField } from "@angular/material/form-field";
import { MatSelect } from "@angular/material/select";
import { Router } from "@angular/router";
import { AuthService, User } from "@auth0/auth0-angular";
import { TranslateModule } from "@ngx-translate/core";
import { NgEventBus } from "ng-event-bus";
import { DropdownComponent } from "../../../../packages/ui/src/primitives/dropdown/dropdown.component";
import { TooltipDirective } from "../../common/tooltip.directive";
import { IconsComponent } from "../../icons/icons.component";
import DialogModel from "../../models/common/DialogModel";
import { Constants } from "../../models/constants/Constants";
import { EventType } from "../../models/constants/eventType";
import { serviceFactory } from "../../services/serviceLayer/servicefactory/serviceFactory";
import { ConfirmationDialogComponent } from "../../shared/confirmation-dialog/confirmation-dialog.component";
import { InformationPageComponent } from "../../shared/information-page/information-page.component";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
  standalone: true,
  imports: [
    MatFormField,
    IconsComponent,
    MatSelect,
    NgFor,
    MatOption,
    NgIf,
    TooltipDirective,
    AsyncPipe,
    LowerCasePipe,
    TranslateModule,
    OverlayModule,
    CdkListbox,
    CdkOption,
    DropdownComponent,
    InformationPageComponent,
  ],
})
export class HeaderComponent {
  localeList: Array<string> = [];
  selectedValue: string = "EN";
  dialogModel = new DialogModel();
  user$: any;
  langPreFixForTranslation = Constants.LANGUAGE_PREFIX;
  isOpen = false;
  isUserOverOpen = false;
  isInfoOverOpen = false;
  scrollStrategy = this.overlay.scrollStrategies.reposition();
  constructor(
    private dialog: MatDialog,
    public router: Router,
    public eventBus: NgEventBus,
    private authService: AuthService,
    private overlay: Overlay
  ) {
    this.user$ = this.authService.user$;
    this.localeList = serviceFactory.LocalizationService.getLocaleList();
    this.selectedValue = localStorage.getItem("i18nextLng");
  }

  changeLocaleLanguage(locale: Array<string>) {
    this.selectedValue = localStorage.getItem("i18nextLng");
    this.dialogModel.content = this.router.url.includes("/projects/")
      ? "Action_save_message"
      : "confirmation-dialog.confirmContent";
    this.dialogModel.header = "confirmation-dialog.header";
    this.dialogModel.actions = [
      {
        action: "confirmation-dialog.discard",
        type: "secondary",
      },
      {
        action: "confirmation-dialog.proceed",
        type: "primary",
      },
    ];
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "37.5rem",
      height: "13rem",
      data: this.dialogModel,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedValue = locale[0];
        localStorage.setItem("i18nextLng", locale[0].toLowerCase());
        /**
         * FIXME: Removed by Nathalie to make tests work. Is this needed?
         */

        //  if (this.router.url.includes("/projects/")) {
        //     this.eventBus.cast(EventType.SAVECONFIGONLOCALECHANGE, result);
        //   } else {
        // this.authService.loginWithRedirect();
        window.location.reload();
        // }
      } else {
        this.selectedValue = localStorage.getItem("i18nextLng");
      }
    });
  }

  async onLogout() {
    if (this.router.url.includes("/projects/")) {
      this.eventBus.cast(EventType.AUTOSAVEONLOGOUT, true);
    }
    this.authService.logout({
      openUrl: () => {
        this.router.navigate(["/login"], { queryParams: { isNotAuthenticated: true } });
      },
    });
  }

  getUserInitials(user: User) {
    const { given_name, family_name } = user;
    const initials = `${given_name?.charAt(0).toUpperCase()}${family_name?.charAt(0).toUpperCase()}`;

    return initials;
  }
}
