import { Component } from "@angular/core";

@Component({
    selector: "app-logout-icon",
    templateUrl: "./logout-icon.component.html",
    standalone: true,
})
export class LogoutIconComponent {
  constructor() {}

}
