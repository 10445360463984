import Highcharts from "highcharts";
import { AuxillaryLineFactors } from "../../models/constants/AuxillaryLineFactors";
import AuxillaryLineModel from "../../models/devices/AuxillaryLineModel";

export default class AuxillaryLineService {
  tempRenderer;
  drawAuxillaryLineForIc(chartData) {
    this.tempRenderer ? "" : (this.tempRenderer = this.createTempRender());
    if (chartData && chartData.ref) {
      let yMax = chartData.ref.yAxis[0].max;
      let yMin = chartData.ref.yAxis[0].min;
      let xMin = chartData.ref.xAxis[0].min;
      let xMax = chartData.ref.xAxis[0].max;
      let x1, y1, x2, y2;
      let isMaxXValueExceeded = false;
      let factor = AuxillaryLineFactors.IC;
      this.removePathsFromChart(chartData);
      factor.forEach((factorX) => {
        let xMinValue = Math.pow(10, yMin) / (factorX * Math.sqrt(2));
        let xMaxValue = Math.pow(10, yMax) / (factorX * Math.sqrt(2));
        if (xMinValue < Math.pow(10, xMin)) {
          x1 = Math.pow(10, xMin);
          y1 = Math.pow(10, xMin) * factorX * Math.sqrt(2);
        } else {
          y1 = Math.pow(10, yMin);
          x1 = xMinValue;
        }

        if (xMaxValue > Math.pow(10, xMax)) {
          x2 = Math.pow(10, xMax);
          y2 = Math.pow(10, xMax) * factorX * Math.sqrt(2);
          isMaxXValueExceeded = true;
        } else {
          x2 = xMaxValue;
          y2 = Math.pow(10, yMax);
          isMaxXValueExceeded = false;
        }

        let auxLineModel = this.convertValueToPixels(chartData, x1, y1, x2, y2);
        let labelPos = this.getScalingFactor(
          x2,
          y2,
          xMin,
          xMax,
          yMin,
          yMax,
          chartData
        );
        this.drawAuxLinePaths(chartData, auxLineModel);
        this.drawIcLabel(
          chartData,
          auxLineModel,
          labelPos,
          this.getIcLabel(factorX),
          isMaxXValueExceeded
        );
      });
    }
  }
  convertValueToPixels(chartData, x1, y1, x2, y2): AuxillaryLineModel {
    let auxLineModel = new AuxillaryLineModel();
    auxLineModel.xStart = chartData.ref.xAxis[0].toPixels(x1);
    auxLineModel.yStart = chartData.ref.yAxis[0].toPixels(y1);
    auxLineModel.xEnd = chartData.ref.xAxis[0].toPixels(x2);
    auxLineModel.yEnd = chartData.ref.yAxis[0].toPixels(y2);

    return auxLineModel;
  }
  drawAuxLinePaths(chartData, auxLineModel: AuxillaryLineModel) {
    chartData.customPaths.push(
      chartData.ref.renderer
        .path([
          ["M", auxLineModel.xStart, auxLineModel.yStart],
          ["L", auxLineModel.xEnd, auxLineModel.yEnd],
        ])
        .attr({
          stroke: "#CCCCD4",
        })
        .add()
    );
  }
  drawIcLabel(
    chartData,
    auxLineModel: AuxillaryLineModel,
    labelPos,
    labelToBeShown: string,
    isMaxXValueExceeded: boolean
  ) {
    let angleRadians = Math.atan(
      (auxLineModel.yEnd - auxLineModel.yStart) /
        (auxLineModel.xEnd - auxLineModel.xStart)
    );
    let labelAngle = (angleRadians * 180) / Math.PI;

    let labelXPos, labelYPos;

    if (auxLineModel.xEnd > labelPos[0]) {
      labelXPos = labelPos[0];
      labelYPos =
        auxLineModel.yStart +
        ((labelPos[0] - auxLineModel.xStart) *
          (auxLineModel.yEnd - auxLineModel.yStart)) /
          (auxLineModel.xEnd - auxLineModel.xStart);
    } else {
      labelXPos = auxLineModel.xEnd;
      labelYPos = auxLineModel.yEnd;
    }
    let label = this.tempRenderer
      .text(labelToBeShown, 100, 100)
      .attr({
        rotation: labelAngle,
      })
      .css({
        fontSize: "14px",
        fontWeight: "600",
      })
      .add() as any;

    let labelWidth = label.element.getBBox().width;
    if (!isMaxXValueExceeded) {
      if (angleRadians > -0.8) {
        labelWidth += 20;
      } else {
        labelWidth += 5;
      }
    }
    chartData.customPaths.push(
      chartData.ref.renderer
        .text(
          labelToBeShown,
          labelXPos - Math.cos(angleRadians) * labelWidth - 5,
          labelYPos - Math.sin(angleRadians) * labelWidth
        )
        .attr({
          rotation: labelAngle,
        })
        .css({
          fontSize: "14px",
          fontWeight: "600",
        })
        .add()
    );
  }
  getIcLabel(factor: number) {
    return factor === 1
      ? "<span>&radic;2</span>"
      : factor.toLocaleString(localStorage.getItem("i18nextLng")) +
          "* <span>&radic;2</span>";
  }
  getI2tLabel(factor: number) {
    return (
      factor.toLocaleString(localStorage.getItem("i18nextLng"), {
        maximumFractionDigits: 4,
      }) + " s"
    );
  }
  drawAuxillaryLineForI2t(chartData) {
    this.tempRenderer ? "" : (this.tempRenderer = this.createTempRender());
    if (chartData && chartData.ref) {
      let yMax = chartData.ref.yAxis[0].max;
      let yMin = chartData.ref.yAxis[0].min;
      let xMin = chartData.ref.xAxis[0].min;
      let xMax = chartData.ref.xAxis[0].max;
      let isMaxXValueExceeded = false;
      let x1, y1, x2, y2;
      let factor = AuxillaryLineFactors.I2T;

      this.removePathsFromChart(chartData);

      factor.forEach((factorX) => {
        let xMinValue = Math.sqrt(Math.pow(10, yMin) / factorX);
        let xMaxValue = Math.sqrt(Math.pow(10, yMax) / factorX);
        if (xMinValue < Math.pow(10, xMin)) {
          x1 = Math.pow(10, xMin);
          y1 = Math.pow(Math.pow(10, xMin), 2) * factorX;
        } else {
          x1 = xMinValue;
          y1 = Math.pow(10, yMin);
        }

        if (xMaxValue > Math.pow(10, xMax)) {
          x2 = Math.pow(10, xMax);
          y2 = Math.pow(Math.pow(10, xMax), 2) * factorX;
          isMaxXValueExceeded = true;
        } else {
          x2 = xMaxValue;
          y2 = Math.pow(10, yMax);
          isMaxXValueExceeded = false;
        }

        let auxLineModel = this.convertValueToPixels(chartData, x1, y1, x2, y2);

        let labelPos = this.getScalingFactor(
          x2,
          y2,
          xMin,
          xMax,
          yMin,
          yMax,
          chartData
        );

        if (x1 != x2 && x1 < x2 && y1 != y2) {
          this.drawAuxLinePaths(chartData, auxLineModel);
          this.drawIcLabel(
            chartData,
            auxLineModel,
            labelPos,
            this.getI2tLabel(factorX),
            isMaxXValueExceeded
          );
        }
      });
    }
  }
  getScalingFactor(xEnd, yEnd, xMinExp, xMaxExp, yMinExp, yMaxExp, chartData) {
    let xPos =
      chartData.ref.xAxis[0].toPixels(Math.pow(10, xMinExp)) +
      this.scale(
        Math.log10(xEnd),
        xMinExp,
        this.getGridScale(xMinExp, xMaxExp, chartData.ref.xAxis[0].width)
      );
    let yPos =
      chartData.ref.yAxis[0].toPixels(Math.pow(10, yMinExp)) +
      chartData.ref.xAxis[0].height -
      (isFinite(Math.log10(yEnd))
        ? 0
        : this.scale(
            Math.log10(yEnd),
            yMinExp,
            this.getGridScale(yMinExp, yMaxExp, chartData.ref.xAxis[0].height)
          ));
    return [xPos, yPos];
  }
  getGridScale(minExp, maxExp, gridScaleArea) {
    return (gridScaleArea - 1) / (maxExp - minExp);
  }
  scale(d, base, scaleFactor) {
    return (d - base) * scaleFactor;
  }
  createTempRender() {
    let tempDiv = document.createElement("div");
    tempDiv.style.position = "absolute";
    tempDiv.style.left = "-9999px";
    document.body.appendChild(tempDiv);
    return new Highcharts.SVGRenderer(tempDiv, 600, 400);
  }

  removePathsFromChart(chartData) {
    if (chartData.customPaths) {
      chartData.customPaths.forEach((path) => {
        path.destroy();
      });
      chartData.customPaths = [];
    } else {
      chartData.customPaths = [];
    }
  }
}
