<div>
  <label class="d-block mb-2">
    {{ inputLabel }}
  </label>
  <input
    ui-input
    class="w-100"
    [(ngModel)]="translatedInputValue"
    (change)="onValueChange($event.target.value)"
    appTooltip
    tooltip="{{ '[ ' + tranlatedTooltipValue + ' ]' }}"
  />
</div>
